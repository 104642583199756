import React from "react";
import { Info, ChevronRight } from "lucide-react";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "./ui/tooltip";

const ProfileCompletionBanner = ({
  username,
  division,
  team,
  timeZone,
  onDismiss,
  onNavigate,
}) => {
  const fields = [
    { name: "Division", value: division },
    { name: "Team", value: team },
    { name: "Time Zone", value: timeZone },
  ];

  const completedFields = fields.filter((f) => f.value).length;
  const percentage = Math.round((completedFields / fields.length) * 100);

  if (percentage === 100) return null;

  return (
    <div className="w-full bg-white rounded-lg border shadow-sm p-6">
      <div className="flex items-start justify-between gap-8">
        {/* Left section with percentage */}
        <div className="flex items-baseline gap-2">
          <span className="text-5xl font-bold text-blue-600">
            {percentage}%
          </span>
          <span className="text-sm text-gray-500">
            of core profile fields complete
          </span>
        </div>

        {/* Middle section with message and button */}
        <div className="flex-1">
          <div className="h-2 bg-gray-100 rounded-full mb-3">
            <div
              className="h-full bg-blue-600 rounded-full transition-all duration-500"
              style={{ width: `${percentage}%` }}
            />
          </div>
          <p className="text-sm text-gray-600 mb-3">
            Complete your profile to access all features and customize your
            experience!
          </p>
          <button
            onClick={onNavigate}
            className="inline-flex items-center gap-2 px-4 py-2 bg-blue-600 text-white text-sm font-medium rounded-lg hover:bg-blue-700 transition-colors"
          >
            Complete profile
            <ChevronRight className="h-4 w-4" />
          </button>
        </div>

        {/* Right section with info button */}
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <button className="flex-shrink-0 text-gray-400 hover:text-gray-600 transition-colors">
                <Info className="h-5 w-5" />
              </button>
            </TooltipTrigger>
            <TooltipContent className="max-w-xs p-4">
              <div className="space-y-2">
                <p className="font-medium">Why complete your profile?</p>
                <ul className="text-sm list-disc pl-4 space-y-1">
                  <li>
                    Core fields (Division, Team, Time Zone) are required for
                    full platform functionality
                  </li>
                  <li>Enable team collaboration features</li>
                  <li>Get notifications in your correct time zone</li>
                  <li>
                    Additional fields like profile photo can be added to enhance
                    your profile
                  </li>
                </ul>
              </div>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>
    </div>
  );
};

export default ProfileCompletionBanner;
