import React, { useEffect, useState, useContext } from 'react';
import UsersList from '../components/UsersList';
import ErrorModal from '../../shared/components/UIElements/ErrorModal';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { AuthContext } from "../../shared/context/auth-context";
import { Box } from '@mui/material';

const APIIntegration = () => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [loadedUsers, setLoadedUsers] = useState();
  const auth = useContext(AuthContext);

  const trackPageVisit = async (pageName) => {
    try {
      await sendRequest(
        `${process.env.REACT_APP_API_URL}/users/track-page-visit`,
        "POST",
        JSON.stringify({
          user_id: auth.user_id,
          page_name: pageName,
        }),
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.token}`,
        },
      );
    } catch (error) {
      console.error("Error tracking page visit:", error);
    }
  };

  useEffect(() => {
    if (auth && auth.user_id) {
      trackPageVisit("api_integration");
    }
  }, [auth]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_API_URL}/users/all`,
          'GET',
          null,
          {
            Authorization: `Bearer ${auth.token}`,
          }
        );
        const apiUsers = responseData.filter(user => user.api_account === true);
        setLoadedUsers(apiUsers);
      } catch (err) {
        console.error("Error fetching users:", err);
      }
    };
    if (auth && auth.token) {
      fetchUsers();
    }
  }, [sendRequest, auth]);

  return (
    <Box 
      sx={{ 
        width: '100%',
        minHeight: '100vh',
        paddingTop: '24px',
      }}
    >
      <ErrorModal error={error} onClear={clearError} />
      {isLoading && (
        <div className="center">
          <LoadingSpinner />
        </div>
      )}
      {!isLoading && loadedUsers && (
        <UsersList 
          items={loadedUsers} 
          showDivisionFilters={false}
          showStatusFilters={false}
          onlineUsersComponent={null}
        />
      )}
    </Box>
  );
};

export default APIIntegration;
