import React from 'react';
import { Box, TextField, FormControlLabel, Checkbox, Typography, Tooltip } from '@mui/material';
import { Info } from 'lucide-react';

const SetUp = ({
  campaignName,
  setCampaignName,
  leadSource,
  setLeadSource,
  eventName,
  setEventName,
  destinations,
  setDestinations,
}) => {
  const handleCheckboxChange = (event) => {
    setDestinations({
      ...destinations,
      [event.target.name]: event.target.checked,
    });
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom>Set Up</Typography>
      
      <Box className="relative">
        <TextField
          fullWidth
          label="Campaign Name (Optional)"
          value={campaignName}
          onChange={(e) => setCampaignName(e.target.value)}
          margin="normal"
          helperText="Identify your campaign for tracking purposes"
        />
      </Box>

      <Box className="relative">
        <TextField
          fullWidth
          label="Lead Source (Optional)"
          value={leadSource}
          onChange={(e) => setLeadSource(e.target.value)}
          margin="normal"
          helperText="Track where these leads originated from"
        />
      </Box>

      <Box className="relative">
        <TextField
          fullWidth
          label="Event Name (Optional)"
          value={eventName}
          onChange={(e) => setEventName(e.target.value)}
          margin="normal"
          helperText="Specify if these leads are from an event"
        />
      </Box>

      <Box className="flex items-center mt-4 mb-2">
        <Typography variant="subtitle1">Lead Destinations:</Typography>
        <Tooltip title="Choose where to send your leads">
          <Info size={16} className="ml-2 text-gray-500" />
        </Tooltip>
      </Box>

      <Tooltip title="Coming soon!" placement="right">
        <span>
          <FormControlLabel
            control={
              <Checkbox
                checked={destinations.send_to_nurture}
                onChange={handleCheckboxChange}
                name="send_to_nurture"
                disabled
              />
            }
            label="Nurture"
            className="text-gray-400"
          />
        </span>
      </Tooltip>

      <Tooltip title="Coming soon!" placement="right">
        <span>
          <FormControlLabel
            control={
              <Checkbox
                checked={destinations.send_to_crm}
                onChange={handleCheckboxChange}
                name="send_to_crm"
                disabled
              />
            }
            label="CRM"
            className="text-gray-400"
          />
        </span>
      </Tooltip>

      <FormControlLabel
        control={
          <Checkbox
            checked={destinations.enrichment_only}
            onChange={handleCheckboxChange}
            name="enrichment_only"
          />
        }
        label="Enrichment Only"
      />
    </Box>
  );
};

export default SetUp;
