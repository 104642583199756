import React, { useEffect, useState, useContext } from "react";
import UsersList from "../components/UsersList";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import { Avatar, AvatarGroup, Tooltip, Box, Typography } from "@mui/material";
import userImage from "../components/user.png";

const statusColors = {
  Available: '#44b700',  // Green
  Busy: '#ff3d00',      // Red
  Away: '#ffa726',      // Orange
  Offline: '#bdbdbd'    // Gray
};

const StatusAvatar = ({ user }) => {
  const statusColor = statusColors[user.status] || statusColors.Offline;
  
  return (
    <Tooltip 
      title={`${user.username} (${user.status})`} 
      key={user.user_id}
    >
      <Avatar
        alt={user.username}
        src={user.image_url || userImage}
        sx={{ 
          width: 28,
          height: 28,
          border: '2px solid #fff',
          backgroundColor: 'white',
          '&:before': {
            content: '""',
            position: 'absolute',
            bottom: 0,
            right: 0,
            width: '8px',
            height: '8px',
            backgroundColor: statusColor,
            borderRadius: '50%',
            border: '2px solid #fff'
          }
        }}
      />
    </Tooltip>
  );
};

const OnlineUsers = ({ users }) => {
  // Only show active users in the online users component
  const activeUsers = users.filter(user => 
    (user.status === 'Available' || 
    user.status === 'Busy' || 
    user.status === 'Away')
  );

  return (
    <Box 
      sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        ml: 2,
        backgroundColor: '#f5f5f5',
        padding: '4px 12px',
        borderRadius: '8px',
        boxShadow: '0 1px 3px rgba(0,0,0,0.1)'
      }}
    >
      <Typography 
        variant="body2" 
        sx={{ 
          mr: 1,
          color: '#666',
          fontWeight: 500
        }}
      >
        Active:
      </Typography>
      <AvatarGroup 
        max={6} 
        sx={{
          '& .MuiAvatar-root': { 
            width: 28, 
            height: 28,
            marginLeft: '-8px',
            '&:first-of-type': {
              marginLeft: 0
            }
          }
        }}
      >
        {activeUsers.map((user) => (
          <StatusAvatar key={user.user_id} user={user} />
        ))}
      </AvatarGroup>
      <Typography 
        variant="body2" 
        sx={{ 
          ml: 1,
          color: '#666',
        }}
      >
        ({activeUsers.length})
      </Typography>
    </Box>
  );
};

const Users = () => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [loadedUsers, setLoadedUsers] = useState([]);
  const [onlineUsers, setOnlineUsers] = useState([]);
  const auth = useContext(AuthContext);

  const trackPageVisit = async (pageName) => {
    try {
      await sendRequest(
        `${process.env.REACT_APP_API_URL}/users/track-page-visit`,
        "POST",
        JSON.stringify({
          user_id: auth.user_id,
          page_name: pageName,
        }),
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.token}`,
        }
      );
    } catch (error) {
      console.error("Error tracking page visit:", error);
    }
  };

  useEffect(() => {
    if (auth && auth.user_id) {
      trackPageVisit("all_users");
    }
  }, [auth]);

  const handleUserActivation = async (userId) => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_API_URL}/users/all`,
        "GET",
        null,
        {
          Authorization: `Bearer ${auth.token}`,
        }
      );
      
      const nonApiUsers = responseData.filter(
        (user) => user.api_account !== true
      );
      setLoadedUsers(nonApiUsers);
      updateOnlineUsers(nonApiUsers);
    } catch (err) {
      console.error("Error refreshing users:", err);
    }
  };

  const updateOnlineUsers = (users) => {
    const currentTime = new Date();
    const activeUsers = users.filter((user) => {
      if (!user.active) return false;
      
      if (user.token_expiry && new Date(user.token_expiry) > currentTime) {
        if (user.last_activity) {
          const lastActivityTime = new Date(user.last_activity);
          const diffMs = currentTime - lastActivityTime;
          const diffMins = diffMs / (1000 * 60);
          const isRecentlyActive = diffMins < 15;
          
          if (isRecentlyActive) {
            return ['Available', 'Busy', 'Away'].includes(user.status);
          }
        }
      }
      return false;
    });
    setOnlineUsers(activeUsers);
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_API_URL}/users/all`,
          "GET",
          null,
          {
            Authorization: `Bearer ${auth.token}`,
          }
        );
        const nonApiUsers = responseData.filter(
          (user) => user.api_account !== true
        );
        setLoadedUsers(nonApiUsers);
        updateOnlineUsers(nonApiUsers);
      } catch (err) {
        console.error("Error fetching users:", err);
      }
    };

    if (auth && auth.token) {
      fetchUsers();
    }
  }, [sendRequest, auth]);

  return (
    <div className="users-page-container">
      <ErrorModal error={error} onClear={clearError} />
      {isLoading && (
        <div className="center">
          <LoadingSpinner />
        </div>
      )}
      {!isLoading && loadedUsers && (
        <div className="users-list-wrapper">
          <UsersList 
            items={loadedUsers} 
            onlineUsersComponent={<OnlineUsers users={onlineUsers} />}
            onActivateUser={handleUserActivation}
            isCurrentUserAdmin={auth.role === 'admin'}
          />
        </div>
      )}
    </div>
  );
};

export default Users;
