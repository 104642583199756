import React, { useState, useEffect } from "react";
import { Calendar } from "lucide-react";
import { useHttpClient } from "../../shared/hooks/http-hook";

const getEventBgColor = (type) => {
  switch (type) {
    case "meeting":
      return "bg-blue-50";
    case "deadline":
      return "bg-purple-50";
    case "conference":
      return "bg-green-50";
    case "webinar":
      return "bg-orange-50";
    case "review":
      return "bg-pink-50";
    default:
      return "bg-gray-50";
  }
};

const getEventIconColor = (type) => {
  switch (type) {
    case "meeting":
      return "text-blue-600";
    case "deadline":
      return "text-purple-600";
    case "conference":
      return "text-green-600";
    case "webinar":
      return "text-orange-600";
    case "review":
      return "text-pink-600";
    default:
      return "text-gray-600";
  }
};

const UpcomingEventsPreview = ({ teamMembers, auth, refreshTrigger }) => {
  const [events, setEvents] = useState({ upcoming: [], active: [] });
  const [isLoading, setIsLoading] = useState(false);
  const { sendRequest } = useHttpClient();

  const fetchEvents = async () => {
    setIsLoading(true);
    try {
      const eventPromises = teamMembers.map((member) =>
        sendRequest(
          `${process.env.REACT_APP_API_URL}/events/user/${member.user_id}`,
          "GET",
          null,
          {
            Authorization: `Bearer ${auth.token}`,
          },
        ).catch((err) => {
          console.error(
            `Error fetching events for user ${member.user_id}:`,
            err,
          );
          return [];
        }),
      );

      const allUserEvents = await Promise.all(eventPromises);
      const eventMap = new Map();
      const now = new Date();
      const upcoming = [];
      const active = [];

      allUserEvents.flat().forEach((event) => {
        if (eventMap.has(event.event_id)) return;

        const startDate = new Date(event.startDate);
        const endDate = event.endDate ? new Date(event.endDate) : startDate;
        endDate.setHours(23, 59, 59, 999);

        if (startDate <= now && endDate >= now) {
          active.push(event);
          eventMap.set(event.event_id, true);
        } else if (startDate > now) {
          upcoming.push(event);
          eventMap.set(event.event_id, true);
        }
      });

      setEvents({
        upcoming: upcoming
          .sort((a, b) => new Date(a.startDate) - new Date(b.startDate))
          .slice(0, 2),
        active: active
          .sort(
            (a, b) =>
              new Date(b.endDate || b.startDate) -
              new Date(a.endDate || a.startDate),
          )
          .slice(0, 2),
      });
    } catch (err) {
      console.error("Error fetching events:", err);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (teamMembers?.length > 0 && auth.token) {
      fetchEvents();
    }
  }, [teamMembers, auth.token, refreshTrigger]);

  const formatEventTime = (event) => {
    const startDate = new Date(
      `${event.startDate}${event.time ? ` ${event.time}` : ""}`,
    );
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);

    let dateString = "";
    if (startDate.toDateString() === today.toDateString()) {
      dateString = "Today";
    } else if (startDate.toDateString() === tomorrow.toDateString()) {
      dateString = "Tomorrow";
    } else {
      dateString = startDate.toLocaleDateString();
    }

    // Add time for single-day events
    if (event.time) {
      dateString += ` at ${formatTime(event.time)}`;
    }

    // Add end date for multi-day events
    if (event.endDate && event.endDate !== event.startDate) {
      dateString += ` - ${new Date(event.endDate).toLocaleDateString()}`;
    }

    return dateString;
  };

  const formatTime = (time) => {
    return new Date(`2000-01-01 ${time}`).toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  };

  if (isLoading) {
    return (
      <div className="flex justify-center py-4">
        <div className="text-sm text-gray-500">Loading events...</div>
      </div>
    );
  }

  const renderEventsList = (eventsList, title) => (
    <div>
      <h4 className="text-sm font-medium text-gray-700 mb-3">{title}</h4>
      {eventsList.length > 0 ? (
        <div className="space-y-4">
          {eventsList.map((event) => (
            <div key={event.id} className="flex items-center space-x-3">
              <div
                className={`w-10 h-10 rounded-lg flex items-center justify-center ${getEventBgColor(event.type)}`}
              >
                <Calendar
                  className={`w-5 h-5 ${getEventIconColor(event.type)}`}
                />
              </div>
              <div>
                <p className="text-sm font-medium text-gray-900">
                  {event.title}
                </p>
                <p className="text-xs text-gray-500">
                  {formatEventTime(event)}
                </p>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="text-sm text-gray-500 text-center py-2">
          No {title.toLowerCase()}
        </div>
      )}
    </div>
  );

  return (
    <div className="space-y-6">
      {renderEventsList(events.active, "Active Events")}
      {renderEventsList(events.upcoming, "Upcoming Events")}
    </div>
  );
};

export default UpcomingEventsPreview;
