import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { useHttpClient } from "../../shared/hooks/http-hook";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarQuickFilter,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Portal from "@mui/material/Portal";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Popover from "@mui/material/Popover";
import DateRangeIcon from "@mui/icons-material/DateRange";
import { parseISO } from "date-fns";
import { formatInTimeZone, utcToZonedTime } from "date-fns-tz";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { AuthContext } from "../../shared/context/auth-context";
import "./UserLeads.css";

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: "none",
          backgroundColor: "#ffffff",
        },
        columnHeaders: {
          backgroundColor: "#f5f5f5",
          color: "#000",
          fontWeight: "bold",
        },
        columnHeaderTitle: {
          fontSize: "16px",
        },
        cell: {
          borderBottom: "1px solid #ddd",
        },
        row: {
          "&:hover": {
            backgroundColor: "#e3f2fd",
          },
        },
        footerContainer: {
          backgroundColor: "#f1f3f4",
        },
      },
    },
  },
});

const formatDate = (dateString, timeZone) => {
  try {
    if (!dateString) {
      return "Invalid Date";
    }

    // Ensure the date string is ISO format and treated as UTC
    let isoDateString = dateString.includes("T")
      ? dateString
      : dateString.replace(" ", "T");
    if (!isoDateString.endsWith("Z")) {
      isoDateString += "Z"; // Explicitly marking it as UTC
    }

    // Parse the ISO date string
    const date = parseISO(isoDateString);

    if (isNaN(date.getTime())) {
      return "Invalid Date";
    }

    // Format the date in the user's time zone
    return formatInTimeZone(date, timeZone, "MM/dd/yyyy hh:mm a");
  } catch (error) {
    console.error("Date formatting error:", error);
    return "Invalid Date";
  }
};

const CustomPagination = () => {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, (state) => state.pagination.page);
  const pageCount = useGridSelector(
    apiRef,
    (state) => state.pagination.pageCount,
  );

  return (
    <Pagination
      color="primary"
      count={pageCount}
      page={page + 1}
      renderItem={(props) => <PaginationItem {...props} disableRipple />}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
};

const DateRangeFilter = ({ onFilterChange, userTimeZone }) => {
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const applyQuickFilter = (days) => {
    const today = new Date();
    const from = new Date();
    from.setDate(today.getDate() - days);

    setFromDate(formatInTimeZone(from, userTimeZone, "yyyy-MM-dd"));
    setToDate(formatInTimeZone(today, userTimeZone, "yyyy-MM-dd"));
    onFilterChange(from, today);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
        padding: 2,
        width: 250,
      }}
    >
      <TextField
        label="From"
        type="date"
        value={fromDate}
        onChange={(e) => setFromDate(e.target.value)}
        InputLabelProps={{ shrink: true }}
        fullWidth
      />
      <TextField
        label="To"
        type="date"
        value={toDate}
        onChange={(e) => setToDate(e.target.value)}
        InputLabelProps={{ shrink: true }}
        fullWidth
      />
      <Button onClick={() => applyQuickFilter(7)}>Past 7 Days</Button>
      <Button onClick={() => applyQuickFilter(1)}>Yesterday</Button>
      <Button onClick={() => applyQuickFilter(30)}>Past Month</Button>
      <Button
        variant="contained"
        onClick={() => onFilterChange(parseISO(fromDate), parseISO(toDate))}
        fullWidth
      >
        Apply
      </Button>
    </Box>
  );
};

const CustomToolbar = ({ onFilterChange, userTimeZone }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "date-range-popover" : undefined;

  return (
    <GridToolbarContainer
      style={{
        display: "flex",
        alignItems: "center",
        gap: "4px", // Reduces gap between buttons
        padding: "4px 0", // Removes extra padding
      }}
    >
      <Portal container={() => document.getElementById("filter-panel")}>
        <GridToolbarQuickFilter />
      </Portal>
      <Box sx={{ display: "flex", gap: "8px" }}>
        {" "}
        {/* Adjusting gap */}
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport />
      </Box>
      <Button
        aria-describedby={id}
        variant="contained"
        startIcon={<DateRangeIcon />}
        onClick={handleClick}
        sx={{
          marginLeft: "auto",
          padding: "4px 8px",
          fontSize: "0.85rem",
          marginBottom: "6px",
        }}
      >
        Date Range
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <DateRangeFilter
          userTimeZone={userTimeZone}
          onFilterChange={(from, to) => {
            onFilterChange(from, to);
            handleClose();
          }}
        />
      </Popover>
    </GridToolbarContainer>
  );
};

const UserLeads = () => {
  const { error, sendRequest, clearError } = useHttpClient();
  const [leads, setLeads] = useState([]);
  const [filteredLeads, setFilteredLeads] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const user_id = useParams().user_id;
  const auth = useContext(AuthContext);

  const userTimeZone =
    localStorage.getItem("time_zone") ||
    Intl.DateTimeFormat().resolvedOptions().timeZone;

  const fetchLeads = async (fromDate, toDate) => {
    setIsLoading(true);
    try {
      const url = new URL(
        `${process.env.REACT_APP_API_URL}/leads/user/${user_id}`,
      );
      if (fromDate && toDate) {
        url.searchParams.append("fromDate", fromDate.toISOString());
        url.searchParams.append("toDate", toDate.toISOString());
      }

      const responseData = await sendRequest(url.toString(), "GET", null, {
        Authorization: `Bearer ${auth.token}`,
      });

      setLeads(responseData);

      let leadsToDisplay = responseData;

      if (fromDate && toDate) {
        leadsToDisplay = responseData.filter((lead) => {
          const leadDate = parseISO(lead.created_date);
          return leadDate >= fromDate && leadDate <= toDate;
        });
      }
      setFilteredLeads(leadsToDisplay);
    } catch (err) {
      console.error("Error fetching leads:", err);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchLeads();
  }, [auth, user_id]);

  const trackPageVisit = async (pageName) => {
    try {
      await sendRequest(
        process.env.REACT_APP_API_URL + "/users/track-page-visit",
        "POST",
        JSON.stringify({
          user_id: auth.user_id,
          page_name: pageName,
        }),
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.token}`,
        },
      );
    } catch (error) {
      console.error("Error tracking page visit:", error);
    }
  };

  useEffect(() => {
    if (auth && auth.user_id) {
      trackPageVisit("user_leads");
    }
  }, [auth]);

  const columns = [
    {
      field: "created_date",
      headerName: "Created Date",
      width: 200,
      valueFormatter: (params) => formatDate(params.value, userTimeZone),
    },
    { field: "crm_response", headerName: "CRM Response", width: 550 },
    { field: "referral_url", headerName: "Referral URL", width: 550 },
    {
      field: "business_hq_immediate",
      headerName: "Business HQ Immediate",
      width: 200,
    },
    {
      field: "immediate_parent_name",
      headerName: "Immediate Parent Name",
      width: 300,
    },
    { field: "hq_division", headerName: "HQ Division", width: 150 },
    { field: "business_id", headerName: "Business ID", width: 150 },
    { field: "business_name", headerName: "Business Name", width: 300 },
    { field: "address", headerName: "Address", width: 250 },
    { field: "city", headerName: "City", width: 200 },
    { field: "state_or_province", headerName: "State/Province", width: 150 },
    { field: "zip_or_postal_code", headerName: "Zip/Postal Code", width: 150 },
    { field: "country", headerName: "Country", width: 150 },
    { field: "cmcst_division", headerName: "Division", width: 200 },
    { field: "cmcst_region", headerName: "Region", width: 250 },
    { field: "mktg_segment", headerName: "Segment", width: 200 },
    { field: "mktg_subsegment", headerName: "Subsegment", width: 250 },
    { field: "industry", headerName: "Industry", width: 400 },
    { field: "sub_industry", headerName: "Sub-Industry", width: 400 },
    {
      field: "location_headcount",
      headerName: "Location Headcount",
      width: 200,
    },
    { field: "cmcst_status_cb", headerName: "Customer Status", width: 150 },
    { field: "website", headerName: "Website", width: 300 },
    { field: "contact_id", headerName: "Contact ID", width: 150 },
    { field: "contact_first_name", headerName: "First Name", width: 180 },
    { field: "contact_last_name", headerName: "Last Name", width: 180 },
    { field: "contact_job_title", headerName: "Job Title", width: 350 },
    { field: "contact_phone", headerName: "Phone", width: 150 },
    { field: "contact_mobile", headerName: "Mobile", width: 150 },
    { field: "contact_email", headerName: "Email", width: 350 },
    { field: "event_name", headerName: "Event Name", width: 300 },
    { field: "campaign_name", headerName: "Campaign Name", width: 300 },
  ];

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      {isLoading && (
        <div className="center">
          <LoadingSpinner />
        </div>
      )}
      {!isLoading && filteredLeads && (
        <ThemeProvider theme={theme}>
          <div className="user-leads-container">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box id="filter-panel" />
              </Grid>
              <Grid item xs={12}>
                <div className="user-leads-grid">
                  <DataGrid
                    rows={filteredLeads}
                    columns={columns}
                    getRowId={(row) => row.lead_id}
                    pageSize={100}
                    pagination
                    components={{
                      Toolbar: () => (
                        <CustomToolbar onFilterChange={fetchLeads} />
                      ),
                      Pagination: CustomPagination,
                    }}
                    disableSelectionOnClick
                    density="compact"
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        </ThemeProvider>
      )}
    </React.Fragment>
  );
};

export default UserLeads;
