import React, { useEffect, useState, useContext } from "react";
import { useHttpClient } from "../../shared/hooks/http-hook";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import {
  DataGrid,
  useGridApiContext,
  useGridSelector,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import {
  CheckCircle as CheckCircleIcon,
  Pending as PendingIcon,
  CloudDownload as CloudDownloadIcon,
} from "@mui/icons-material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Button, Popover, Box, TextField, Grid } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import { AuthContext } from "../../shared/context/auth-context";
import DateRangeIcon from "@mui/icons-material/DateRange";
import { format, parseISO } from "date-fns";
import { zonedTimeToUtc, utcToZonedTime } from 'date-fns-tz';
import "./UserUploads.css";

const useTimezone = () => {
  const [timezone, setTimezone] = useState(() => {
    const savedTimezone = localStorage.getItem('time_zone');
    if (savedTimezone) return savedTimezone;
    
    try {
      return Intl.DateTimeFormat().resolvedOptions().timeZone;
    } catch (error) {
      console.error('Error detecting timezone:', error);
      return 'UTC';
    }
  });

  useEffect(() => {
    if (timezone) {
      localStorage.setItem('time_zone', timezone);
    }
  }, [timezone]);

  return { timezone };
};

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: "none",
          backgroundColor: "#ffffff",
        },
        columnHeaders: {
          backgroundColor: "#f5f5f5",
          color: "#000",
          fontWeight: "bold",
        },
        columnHeaderTitle: {
          fontSize: "16px",
        },
        cell: {
          borderBottom: "1px solid #ddd",
          backgroundColor: "#ffffff",
        },
        row: {
          backgroundColor: "#ffffff",
          "&:hover": {
            backgroundColor: "#f9f9f9",
          },
        },
        footerContainer: {
          backgroundColor: "#f1f3f4",
        },
      },
    },
  },
});

const CustomPagination = () => {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, (state) => state.pagination.page);
  const pageCount = useGridSelector(apiRef, (state) => state.pagination.pageCount);

  return (
    <Pagination
      color="primary"
      count={pageCount}
      page={page + 1}
      renderItem={(props) => <PaginationItem {...props} disableRipple />}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
};

const CustomToolbar = () => {
  return <GridToolbarContainer />;
};

const formatDate = (dateString, timezone) => {
  try {
    if (!dateString) return '';
    
    // Handle dates that are already formatted
    if (typeof dateString === 'string' && dateString.includes('/')) {
      return dateString;
    }
    
    // Parse the UTC date string
    const utcDate = parseISO(dateString);
    
    // Convert to user's timezone
    const zonedDate = utcToZonedTime(utcDate, timezone);
    
    // Format the date
    return format(zonedDate, 'MM/dd/yyyy', { timeZone: timezone });
  } catch (error) {
    console.error("Error formatting date:", error, dateString);
    return "Invalid Date";
  }
};

const DateRangeFilter = ({ onFilterChange, timezone }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const applyQuickFilter = (days) => {
    const today = new Date();
    const from = new Date();
    from.setDate(today.getDate() - days);

    // Convert dates to UTC before setting them
    const utcFrom = zonedTimeToUtc(from, timezone);
    const utcTo = zonedTimeToUtc(today, timezone);

    setFromDate(format(utcFrom, 'yyyy-MM-dd'));
    setToDate(format(utcTo, 'yyyy-MM-dd'));
    onFilterChange(utcFrom, utcTo);
  };

  const handleApply = () => {
    if (fromDate && toDate) {
      const utcFrom = zonedTimeToUtc(new Date(fromDate), timezone);
      const utcTo = zonedTimeToUtc(new Date(toDate), timezone);
      onFilterChange(utcFrom, utcTo);
      setAnchorEl(null);
    }
  };

  return (
    <div>
      <Button
        startIcon={<DateRangeIcon />}
        onClick={(event) => setAnchorEl(event.currentTarget)}
        variant="contained"
        sx={{ marginRight: 2 }}
      >
        Date Range
      </Button>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <Box sx={{ padding: 2, width: 250 }}>
          <TextField
            label="From"
            type="date"
            value={fromDate}
            onChange={(e) => setFromDate(e.target.value)}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            label="To"
            type="date"
            value={toDate}
            onChange={(e) => setToDate(e.target.value)}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            sx={{ marginTop: 2 }}
          />
          <Button
            variant="contained"
            fullWidth
            sx={{ marginTop: 2 }}
            onClick={handleApply}
          >
            Apply
          </Button>
          <Button
            fullWidth
            sx={{ marginTop: 1 }}
            onClick={() => applyQuickFilter(1)}
          >
            Yesterday
          </Button>
          <Button
            fullWidth
            sx={{ marginTop: 1 }}
            onClick={() => applyQuickFilter(7)}
          >
            Past 7 Days
          </Button>
          <Button
            fullWidth
            sx={{ marginTop: 1 }}
            onClick={() => applyQuickFilter(30)}
          >
            Last Month
          </Button>
        </Box>
      </Popover>
    </div>
  );
};

const UserUploads = () => {
  const { timezone } = useTimezone();
  const { error, sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);
  const [loadedLeads, setLoadedLeads] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filteredLeads, setFilteredLeads] = useState([]);

  const trackPageVisit = async (pageName) => {
    try {
      await sendRequest(
        process.env.REACT_APP_API_URL + "/users/track-page-visit",
        "POST",
        JSON.stringify({
          user_id: auth.user_id,
          page_name: pageName,
        }),
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.token}`,
        }
      );
    } catch (error) {
      console.error("Error tracking page visit:", error);
    }
  };

  useEffect(() => {
    if (auth && auth.user_id) {
      trackPageVisit("user_uploads");
    }
  }, [auth]);

  useEffect(() => {
    const fetchLeads = async () => {
      setIsLoading(true);
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_API_URL}/uploads/${auth.user_id}`,
          "GET",
          null,
          {
            Authorization: `Bearer ${auth.token}`,
            'X-Timezone': timezone
          }
        );

        const formattedData = responseData.map((lead) => ({
          ...lead,
          upload_date: formatDate(lead.upload_date, timezone),
        }));

        setLoadedLeads(formattedData);
        setFilteredLeads(formattedData);
      } catch (err) {
        console.error("Error fetching leads:", err);
      }
      setIsLoading(false);
    };

    if (auth && auth.token) {
      fetchLeads();
    }
  }, [sendRequest, auth, timezone]);

  const handleDownload = async (file_id) => {
    try {
      const response = await sendRequest(
        `${process.env.REACT_APP_API_URL}/downloads/${file_id}`,
        "GET",
        null,
        {
          Authorization: `Bearer ${auth.token}`,
        }
      );

      const { url } = response;

      if (!url) {
        console.error("No download URL received.");
        return;
      }

      window.open(url, "_blank");
    } catch (err) {
      console.error("Failed to download the file:", err);
    }
  };

  const handleFilter = (fromDate, toDate) => {
    const filtered = loadedLeads.filter((lead) => {
      try {
        // Parse the formatted date string
        const [month, day, year] = lead.upload_date.split('/');
        const leadDate = new Date(year, month - 1, day);
        
        // Ensure all dates are compared in UTC
        const utcLeadDate = zonedTimeToUtc(leadDate, timezone);
        const utcFromDate = zonedTimeToUtc(fromDate, timezone);
        const utcToDate = zonedTimeToUtc(toDate, timezone);
        
        return utcLeadDate >= utcFromDate && utcLeadDate <= utcToDate;
      } catch (error) {
        console.error("Error filtering date:", error, lead.upload_date);
        return false;
      }
    });
    setFilteredLeads(filtered);
  };

  const columns = [
    {
      field: "file_id",
      headerName: "File ID",
      width: 150,
      hide: true,
      cellClassName: "centered-cell",
    },
    {
      field: "upload_date",
      headerName: "Upload Date",
      width: 150,
      cellClassName: "centered-cell",
    },
    {
      field: "file_name",
      headerName: "File Name",
      width: 300,
      cellClassName: "centered-cell",
    },
    {
      field: "uploaded_volume",
      headerName: "Uploaded",
      width: 175,
      cellClassName: "centered-cell",
    },
    {
      field: "enriched_volume",
      headerName: "Enriched",
      width: 175,
      cellClassName: "centered-cell",
    },
    {
      field: "leads_sent_to_eloqua",
      headerName: "Sent to Eloqua",
      width: 175,
      cellClassName: "centered-cell",
    },
    {
      field: "leads_sent_to_salesforce",
      headerName: "Sent to Salesforce",
      width: 175,
      cellClassName: "centered-cell",
    },
    {
      field: "leads_sent_to_dynamics",
      headerName: "Sent to Dynamics",
      width: 175,
      cellClassName: "centered-cell",
    },
    {
      field: "leads_rejected",
      headerName: "Rejected",
      width: 175,
      cellClassName: "centered-cell",
    },
    {
      field: "ready_for_export",
      headerName: "Ready for Export",
      width: 175,
      cellClassName: "centered-cell",
      renderCell: (params) =>
        params.value ? (
          <CheckCircleIcon style={{ color: "green" }} />
        ) : (
          <PendingIcon style={{ color: "grey" }} />
        ),
    },
    {
      field: "download",
      headerName: "Download",
      sortable: false,
      cellClassName: "centered-cell",
      renderCell: (params) => (
        <CloudDownloadIcon
          style={{ cursor: "pointer" }}
          onClick={() => handleDownload(params.row.file_id)}
          titleAccess={
            params.row.ready_for_export
              ? "Download Enriched File"
              : "Download Raw File"
          }
        />
      ),
    },
  ];

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      {isLoading && (
        <div className="center">
          <LoadingSpinner />
        </div>
      )}
      {!isLoading && filteredLeads && (
        <ThemeProvider theme={theme}>
          <div className="user-uploads-container">
            <Grid
              container
              justifyContent="flex-end"
              alignItems="center"
              spacing={2}
            >
              <Grid item>
                <DateRangeFilter onFilterChange={handleFilter} timezone={timezone} />
              </Grid>
            </Grid>

            <div
              className="user-uploads-grid"
              style={{ paddingBottom: "770px" }}
            >
              <DataGrid
                rows={filteredLeads}
                columns={columns}
                getRowId={(row) => row.file_id}
                pageSize={10}
                pagination
                components={{
                  Pagination: CustomPagination,
                  Toolbar: CustomToolbar,
                }}
                disableSelectionOnClick
                autoHeight
              />
            </div>
          </div>
        </ThemeProvider>
      )}
    </React.Fragment>
  );
};

export default UserUploads;
