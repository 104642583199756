import * as React from "react";

const Progress = React.forwardRef(({ value, className, ...props }, ref) => {
  return (
    <div
      className={`relative w-full overflow-hidden rounded-full bg-gray-200 h-2 ${className}`}
      ref={ref}
      role="progressbar"
      aria-valuemin={0}
      aria-valuemax={100}
      aria-valuenow={value}
      {...props}
    >
      <div
        className="h-full w-full flex-1 bg-blue-600 transition-all duration-500 ease-in-out"
        style={{ 
          transform: `translateX(-${100 - (value || 0)}%)`,
        }}
      />
    </div>
  );
});

Progress.displayName = "Progress";

export { Progress };
