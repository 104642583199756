import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardContent } from '../../../teamhub/components/ui/card';
import { 
  Search, 
  ChevronRight, 
  ChevronDown, 
  Check,
  Building2,
  BarChart3,
  User,
  MapPin,
  Network,
  Briefcase,
  Target,
  ShieldAlert
} from 'lucide-react';
import { Input } from '../../../teamhub/components/ui/input';

const EnrichmentFieldSelector = ({ onFieldsChange, defaultFields = [] }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [expandedCategories, setExpandedCategories] = useState({
    businessInfo: true,
    contact: true,
    location: true,
    marketing: true
  });
  
  const categories = {
    businessInfo: {
      name: 'Business Information',
      icon: Building2,
      fields: [
        'business_id',
        'business_legal_name',
        'business_trade_name',
        'website',
        'industry',
        'sub_industry',
        'sic_4',
        'vertical',
        'cmcst_status_cb'
      ]
    },
    metrics: {
      name: 'Company Metrics',
      icon: BarChart3,
      fields: [
        'corporate_headcount',
        'corporate_revenue',
        'location_headcount',
        'location_revenue',
        'lead_score'
      ]
    },
    contact: {
      name: 'Contact Details',
      icon: User,
      fields: [
        'contact_id',
        'contact_email',
        'contact_supplemental_email',
        'contact_phone',
        'contact_mobile',
        'contact_linkedin_url',
        'contact_job_title',
        'contact_job_role'
      ]
    },
    location: {
      name: 'Location Information',
      icon: MapPin,
      fields: [
        'cmcst_division',
        'cmcst_region',
        'hq_address',
        'hq_city',
        'hq_state_or_province',
        'hq_country',
        'hq_zip_or_postal_code',
        'hq_division',
        'hq_region'
      ]
    },
    hierarchy: {
      name: 'Corporate Hierarchy',
      icon: Network,
      fields: [
        'business_hq_immediate',
        'business_hq_ultimate',
        'immediate_parent_name',
        'ultimate_parent_name'
      ]
    },
    crm: {
      name: 'CRM Data',
      icon: Briefcase,
      fields: [
        'crm_account_id',
        'crm_account_owner_id',
        'crm_account_owner_name',
        'crm_account_owner_email',
        'crm_queue_id',
        'crm_queue_name'
      ]
    },
    marketing: {
      name: 'Marketing',
      icon: Target,
      fields: [
        'mktg_segment',
        'mktg_subsegment'
      ]
    },
    flags: {
      name: 'Restrictions & Flags',
      icon: ShieldAlert,
      fields: [
        'do_not_contact_email',
        'do_not_contact_mobile',
        'do_not_contact_phone',
        'bad_record_indicator',
        'comcast_internal_indicator'
      ]
    }
  };

  const [selectedFields, setSelectedFields] = useState(defaultFields);

  const getSelectedFieldCount = (categoryFields) => {
    return categoryFields.filter(field => selectedFields.includes(field)).length;
  };

  useEffect(() => {
    if (onFieldsChange) {
      onFieldsChange(selectedFields);
    }
  }, [selectedFields, onFieldsChange]);

  const toggleCategory = (categoryKey) => {
    setExpandedCategories(prev => ({
      ...prev,
      [categoryKey]: !prev[categoryKey]
    }));
  };

  const toggleField = (field) => {
    setSelectedFields(prev => 
      prev.includes(field)
        ? prev.filter(f => f !== field)
        : [...prev, field]
    );
  };

  const handleSelectAll = () => {
    const allFields = Object.values(categories)
      .flatMap(cat => cat.fields);
    setSelectedFields(allFields);
  };

  const handleDeselectAll = () => {
    setSelectedFields([]);
  };

  const formatFieldName = (field) => {
    const acronyms = ['id', 'sic', 'hq', 'cb', 'crm', 'url'];
    
    return field
      .split('_')
      .map(word => {
        if (acronyms.includes(word.toLowerCase())) {
          return word.toUpperCase();
        }
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(' ');
  };

  const filterFields = (fields) => {
    return fields.filter(field => 
      formatFieldName(field).toLowerCase().includes(searchTerm.toLowerCase())
    );
  };

  const shouldShowCategory = (categoryFields) => {
    if (!searchTerm) return true;
    return filterFields(categoryFields).length > 0;
  };

  return (
    <div className="w-full max-w-3xl mx-auto p-4">
      <Card className="bg-white shadow-lg">
        <CardHeader className="space-y-2 pb-2">
          <div className="flex justify-between items-center">
            <div>
              <h3 className="text-lg font-semibold">Select Enrichment Fields</h3>
              <p className="text-sm text-gray-500">Choose the fields to enrich your data</p>
            </div>
            <div className="flex space-x-2">
              <button
                onClick={handleSelectAll}
                className="px-2 py-1 text-xs bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
              >
                Select All
              </button>
              <button
                onClick={handleDeselectAll}
                className="px-2 py-1 text-xs bg-gray-200 text-gray-700 rounded hover:bg-gray-300 transition-colors"
              >
                Clear All
              </button>
            </div>
          </div>
          <div className="relative">
            <Search className="absolute left-2 top-2.5 h-4 w-4 text-gray-400" />
            <Input
              placeholder="Search fields..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="pl-8 bg-gray-50"
            />
          </div>
        </CardHeader>
        <CardContent className="pt-4">
          <div className="space-y-1">
            {Object.entries(categories).map(([categoryKey, category]) => (
              shouldShowCategory(category.fields) && (
                <div key={categoryKey} className="border-b border-gray-100 last:border-0">
                  <button
                    onClick={() => toggleCategory(categoryKey)}
                    className="w-full flex items-center justify-between p-2 hover:bg-gray-50 transition-colors rounded-sm group"
                  >
                    <div className="flex items-center space-x-2">
                      <category.icon className="h-4 w-4 text-gray-500" />
                      <span className="font-medium text-sm text-gray-700 group-hover:text-gray-900">
                        {category.name}
                      </span>
                      <span className="text-xs text-gray-400">
                        ({getSelectedFieldCount(category.fields)} of {filterFields(category.fields).length} fields)
                      </span>
                    </div>
                    {expandedCategories[categoryKey] ? (
                      <ChevronDown className="h-4 w-4 text-gray-400" />
                    ) : (
                      <ChevronRight className="h-4 w-4 text-gray-400" />
                    )}
                  </button>
                  {expandedCategories[categoryKey] && (
                    <div className="grid grid-cols-2 gap-1 p-2 bg-gray-50">
                      {filterFields(category.fields).map((field) => (
                        <button
                          key={field}
                          onClick={() => toggleField(field)}
                          className={`flex items-center justify-between p-2 rounded ${
                            selectedFields.includes(field)
                              ? 'bg-blue-50 text-blue-700'
                              : 'bg-white text-gray-600 hover:bg-gray-100'
                          } transition-colors text-sm`}
                        >
                          <span className="truncate">{formatFieldName(field)}</span>
                          {selectedFields.includes(field) && (
                            <Check className="h-4 w-4 text-blue-500 flex-shrink-0 ml-2" />
                          )}
                        </button>
                      ))}
                    </div>
                  )}
                </div>
              )
            ))}
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default EnrichmentFieldSelector;
