import React, { useState, useEffect } from 'react';
import UserItem from './UserItem';
import {
  Box,
  Card,
  Typography,
  Container,
  TextField,
  InputAdornment,
  IconButton,
  Tooltip,
  Button,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import './UsersList.css';

const UsersList = ({ 
  items, 
  showDivisionFilters = true, 
  showStatusFilters = true,
  onlineUsersComponent, 
  onActivateUser, 
  isCurrentUserAdmin 
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');
  const [selectedDivision, setSelectedDivision] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState('active');

  const divisions = ['HQ', 'Central', 'Northeast', 'West'];
  const statuses = [
    { label: 'Active Users', value: 'active' },
    { label: 'Requested Access', value: 'inactive' }
  ];

  // Calculate division counts
  const divisionCounts = divisions.map((division) => ({
    division,
    count: items.filter((user) => user.division === division).length
  }));

  const statusCounts = {
    active: items.filter(user => user.active).length,
    inactive: items.filter(user => !user.active).length
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  if (items.length === 0) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Card sx={{ padding: '1rem', borderRadius: 1, boxShadow: 2 }}>
          <Typography variant="h6" align="center">
            No users found.
          </Typography>
        </Card>
      </Box>
    );
  }

  const filteredUsers = items
    .filter((user) =>
      user.username.toLowerCase().includes(debouncedSearchTerm.toLowerCase())
    )
    .filter((user) =>
      showDivisionFilters && selectedDivision ? user.division === selectedDivision : true
    )
    .filter((user) => {
      if (!showStatusFilters) return true;
      if (selectedStatus === 'active') return user.active;
      if (selectedStatus === 'inactive') return !user.active;
      return true;
    })
    .sort((a, b) =>
      sortOrder === 'asc'
        ? a.username.localeCompare(b.username)
        : b.username.localeCompare(a.username)
    );

  const handleSortToggle = () => {
    setSortOrder((prevSortOrder) => (prevSortOrder === 'asc' ? 'desc' : 'asc'));
  };

  const handleDivisionClick = (division) => {
    setSelectedDivision((prevDivision) =>
      prevDivision === division ? null : division
    );
  };

  const handleStatusClick = (status) => {
    setSelectedStatus((prevStatus) =>
      prevStatus === status ? null : status
    );
  };

  return (
    <Container
      sx={{
        padding: showDivisionFilters ? 2 : '24px 16px',
        marginBottom: 5,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        maxWidth: 'none',
        minHeight: showDivisionFilters ? '100vh' : 'auto',
      }}
    >
      {/* Search Field and Controls Container */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          maxWidth: 1200,
          marginBottom: showDivisionFilters ? 2 : 3,
          marginLeft: 'auto',
          marginRight: 'auto',
          position: 'relative',
          minHeight: '48px',
          padding: '0 16px',
        }}
      >
        {/* Empty Box for left side balance - Only show if there's an online users component */}
        {onlineUsersComponent && (
          <Box sx={{ flexBasis: '200px', flexShrink: 0 }} />
        )}

        {/* Centered Search and Sort Controls */}
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center',
          width: '100%',
          maxWidth: 500,
          position: 'absolute',
          left: '50%',
          transform: 'translateX(-50%)',
          zIndex: 1,
        }}>
          <TextField
            sx={{ flexGrow: 1, marginRight: 1 }}
            variant="outlined"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
              endAdornment: searchTerm && (
                <InputAdornment position="end">
                  <IconButton onClick={() => setSearchTerm('')}>
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <Tooltip
            title={
              sortOrder === 'asc' ? 'Sort in descending order' : 'Sort in ascending order'
            }
            arrow
          >
            <IconButton
              onClick={handleSortToggle}
              sx={{
                backgroundColor: '#e0e0e0',
                padding: '10px',
                borderRadius: '8px',
                boxShadow: 1,
                transition: 'background-color 0.3s ease',
                '&:hover': {
                  backgroundColor: '#d0d0d0',
                },
              }}
            >
              <SwapVertIcon fontSize="large" />
            </IconButton>
          </Tooltip>
        </Box>

        {/* Online Users Component - Right Side */}
        {onlineUsersComponent && (
          <Box sx={{ 
            flexBasis: '200px', 
            flexShrink: 0,
            display: 'flex',
            justifyContent: 'flex-end'
          }}>
            {onlineUsersComponent}
          </Box>
        )}
      </Box>

      {/* Status Filters - Only show if showStatusFilters is true */}
      {showStatusFilters && (
        <Box 
          sx={{ 
            display: 'flex', 
            justifyContent: 'center', 
            mb: 2,
            maxWidth: 1200,
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          {statuses.map(({ label, value }) => (
            <Button
              key={value}
              onClick={() => handleStatusClick(value)}
              variant="contained"
              sx={{
                mx: 1,
                backgroundColor:
                  selectedStatus === value
                    ? value === 'active' ? '#4CAF50' : '#FF9800'
                    : '#f0f0f0',
                color: selectedStatus === value ? 'white' : 'black',
                '&:hover': {
                  backgroundColor:
                    selectedStatus === value
                      ? value === 'active' ? '#43A047' : '#FB8C00'
                      : '#d0d0d0',
                },
              }}
            >
              {`${label} (${statusCounts[value]})`}
            </Button>
          ))}
        </Box>
      )}

      {/* Division Filters */}
      {showDivisionFilters && (
        <Box 
          sx={{ 
            display: 'flex', 
            justifyContent: 'center', 
            mb: 2, 
            flexWrap: 'wrap', 
            gap: 1,
            maxWidth: 1200,
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          {divisionCounts.map(({ division, count }) => (
            <Button
              key={division}
              onClick={() => handleDivisionClick(division)}
              variant="contained"
              sx={{
                backgroundColor:
                  selectedDivision === division
                    ? '#ADD8E6'
                    : selectedDivision
                    ? '#e0e0e0'
                    : '#f0f0f0',
                color: 'black',
                '&:hover': {
                  backgroundColor:
                    selectedDivision === division ? '#ADD8E6' : '#d0d0d0',
                },
                opacity:
                  selectedDivision && selectedDivision !== division ? 0.6 : 1,
              }}
            >
              {`${division} (${count})`}
            </Button>
          ))}
        </Box>
      )}

      {/* User List */}
      <Box 
        className="users-list-wrapper" 
        sx={{ 
          width: '100%', 
          flexGrow: 1,
          maxWidth: 1200,
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        <ul className="users-list">
          {filteredUsers.map((user) => (
            <UserItem
              key={user.user_id}
              id={user.user_id}
              image={user.image_url}
              username={user.username}
              company={user.company}
              status={user.status}
              api_account={user.api_account}
              division={user.division}
              role={user.role}
              team={user.team}
              active={user.active}
              onActivate={onActivateUser}
              isCurrentUserAdmin={isCurrentUserAdmin}
            />
          ))}
        </ul>
      </Box>
    </Container>
  );
};

export default UsersList;
