import React from 'react';
import { Box, Typography, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

const Review = ({ fileData, headerMapping }) => {
  const mappedData = fileData.map(row => {
    const newRow = {};
    Object.keys(row).forEach(key => {
      const mappedKey = headerMapping[key] || key;
      newRow[mappedKey] = row[key];
    });
    return newRow;
  });

  return (
    <Box>
      <Typography variant="h6" gutterBottom>Review</Typography>
      <Box sx={{ maxHeight: 400, overflow: 'auto' }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {Object.keys(mappedData[0] || {}).map((header) => (
                <TableCell key={header}>{header}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {mappedData.map((row, index) => (
              <TableRow key={index}>
                {Object.values(row).map((value, cellIndex) => (
                  <TableCell key={cellIndex}>{value}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
};

export default Review;
