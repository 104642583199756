import React from 'react';
import { ChevronRight, X, Check, AlertCircle, HelpCircle } from 'lucide-react';
import { Card, CardContent, CardHeader, CardTitle } from "../../../user/components/ui/card";
import { Alert, AlertDescription } from "../../../teamhub/components/ui/alert";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '../../../user/components/ui/tooltip';

const FieldMappingRow = ({ sourceField, targetField, onMapField, onDoNotMap, isMatched, isDontMap, availableFields, fileData, isRequired }) => {
  const valueCount = fileData.filter(row => row[sourceField]).length;
  const sampleValues = fileData.slice(0, 3).map(row => row[sourceField] || '');
  const fillRate = Math.round((valueCount / fileData.length) * 100);
  const isEmpty = valueCount === 0;

  React.useEffect(() => {
    if (isEmpty && targetField !== 'do_not_map') {
      onDoNotMap();
    }
  }, [isEmpty, targetField, onDoNotMap]);

  return (
    <div className="flex items-center space-x-4 p-2 hover:bg-gray-50 rounded-lg group transition-all">
      <div className="w-1/4">
        <div className="p-2 bg-gray-100 rounded-md text-gray-700 font-medium">
          <div className="flex items-center gap-2">
            {sourceField}
            {isRequired && <span className="text-red-500">*</span>}
            {isEmpty && (
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger>
                    <HelpCircle className="h-4 w-4 text-gray-400" />
                  </TooltipTrigger>
                  <TooltipContent>
                    This field contains no data and has been automatically marked as "Do Not Map"
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            )}
          </div>
          <div className="text-xs text-gray-500 mt-1">
            {valueCount}/{fileData.length} records ({fillRate}% filled)
          </div>
        </div>
      </div>
      
      <div className="flex-shrink-0">
        <ChevronRight className={`h-5 w-5 ${isMatched ? 'text-green-500' : 'text-gray-300'}`} />
      </div>
      
      <div className="w-1/4">
        <select 
          value={targetField || ''}
          onChange={(e) => onMapField(e.target.value)}
          disabled={isEmpty}
          className={`w-full p-2 rounded-md border ${
            isMatched ? 'border-green-200 bg-green-50' : 
            isDontMap ? 'border-red-200 bg-red-50' : 
            'border-gray-200'
          } focus:outline-none focus:ring-2 focus:ring-blue-500 ${
            isEmpty ? 'opacity-50 cursor-not-allowed' : ''
          } ${isRequired && !isMatched ? 'border-red-300' : ''}`}
        >
          <option value="">Select field...</option>
          {targetField === 'do_not_map' && <option value="do_not_map">Do Not Map</option>}
          {availableFields.map(field => (
            <option key={field} value={field}>
              {formatHeader(field)}
            </option>
          ))}
        </select>
      </div>

      <div className="w-1/3">
        <div className="text-sm text-gray-600">
          <div className="font-medium mb-1">Sample Values:</div>
          {sampleValues.map((value, idx) => (
            <div key={idx} className="truncate text-xs bg-gray-50 p-1 mb-1 rounded">
              {value || '<empty>'}
            </div>
          ))}
        </div>
      </div>
      
      <div className="flex-shrink-0 flex justify-end space-x-2">
        <button
          onClick={onDoNotMap}
          disabled={isEmpty || isRequired}
          className={`p-1.5 rounded-full transition-colors ${
            isDontMap 
              ? 'bg-red-100 text-red-600 hover:bg-red-200' 
              : 'bg-gray-100 text-gray-400 hover:bg-gray-200'
          } ${(isEmpty || isRequired) ? 'opacity-50 cursor-not-allowed' : ''}`}
        >
          <X className="h-4 w-4" />
        </button>
        {isMatched && (
          <div className="p-1.5 bg-green-100 text-green-600 rounded-full">
            <Check className="h-4 w-4" />
          </div>
        )}
      </div>
    </div>
  );
};

const formatHeader = (header) => {
  return header
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

const MapFields = ({ fileData, headerMapping, setHeaderMapping, expectedHeaders, onValidityChange }) => {
  const requiredFields = ['business_name'];
  const contactFields = ['contact_phone', 'contact_mobile', 'contact_email', 'contact_supplemental_email'];

  React.useEffect(() => {
    const hasBusinessName = Object.values(headerMapping).includes('business_name');
    const hasContactField = Object.values(headerMapping).some(value => 
      contactFields.includes(value)
    );
    
    const isValid = hasBusinessName && hasContactField;
    onValidityChange(isValid);
  }, [headerMapping, onValidityChange]);

  const getAvailableFields = (currentHeader) => {
    const mappedFields = Object.entries(headerMapping)
      .filter(([header, value]) => 
        header !== currentHeader && 
        value !== "do_not_map" && 
        value !== "" &&
        value !== headerMapping[currentHeader]
      )
      .map(([_, value]) => value);

    return expectedHeaders.filter(field =>
      field === "lead_notes" ||
      field === "custom_questions" ||
      field === headerMapping[currentHeader] ||
      !mappedFields.includes(field)
    );
  };

  const isFieldRequired = (sourceField) => {
    const mappedField = headerMapping[sourceField];
    return requiredFields.includes(mappedField) || contactFields.includes(mappedField);
  };

  const unmappedCount = Object.values(headerMapping).filter(v => !v).length;
  const hasBusinessName = Object.values(headerMapping).includes('business_name');
  const hasContactField = Object.values(headerMapping).some(value => contactFields.includes(value));

  return (
    <div className="w-full px-4">
      <Card className="max-w-[95%] mx-auto">
        <CardHeader>
          <CardTitle className="text-xl font-semibold">Map the fields from your file to the expected template fields</CardTitle>
        </CardHeader>
        <CardContent>
          {unmappedCount > 0 && (
            <Alert className="mb-6">
              <AlertCircle className="h-4 w-4" />
              <AlertDescription>
                {unmappedCount} fields still need to be mapped
              </AlertDescription>
            </Alert>
          )}

          {(!hasBusinessName || !hasContactField) && (
            <Alert variant="destructive" className="mb-6">
              <AlertCircle className="h-4 w-4" />
              <AlertDescription>
                Required fields missing:
                {!hasBusinessName && " Business Name"}
                {!hasBusinessName && !hasContactField && " and"}
                {!hasContactField && " at least one contact method (Phone, Mobile, Email, or Supplemental Email)"}
              </AlertDescription>
            </Alert>
          )}

          <div className="flex items-center space-x-4 p-2 mb-4 font-semibold text-sm text-gray-600">
            <div className="w-1/4">Source Field</div>
            <div className="flex-shrink-0 w-5"></div>
            <div className="w-1/4">Target Field</div>
            <div className="w-1/3">Preview</div>
            <div className="flex-shrink-0 w-20">Actions</div>
          </div>

          <div className="space-y-2">
            {Object.keys(fileData[0] || {}).map((sourceField) => (
              <FieldMappingRow
                key={sourceField}
                sourceField={sourceField}
                targetField={headerMapping[sourceField]}
                onMapField={(value) => {
                  setHeaderMapping(prev => ({
                    ...prev,
                    [sourceField]: value
                  }));
                }}
                onDoNotMap={() => {
                  setHeaderMapping(prev => ({
                    ...prev,
                    [sourceField]: 'do_not_map'
                  }));
                }}
                isMatched={headerMapping[sourceField] && headerMapping[sourceField] !== 'do_not_map'}
                isDontMap={headerMapping[sourceField] === 'do_not_map'}
                availableFields={getAvailableFields(sourceField)}
                fileData={fileData}
                isRequired={isFieldRequired(sourceField)}
              />
            ))}
          </div>

          <div className="mt-6 p-4 bg-gray-50 rounded-lg">
            <div className="text-sm text-gray-600">
              <span className="text-red-500">*</span> Required fields:
              <ul className="mt-2 ml-6 list-disc">
                <li>Business Name</li>
                <li>At least one of the following contact methods:
                  <ul className="mt-1 ml-6 list-disc">
                    <li>Phone</li>
                    <li>Mobile Phone</li>
                    <li>Email</li>
                    <li>Supplemental Email</li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default MapFields;
