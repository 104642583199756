import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from './ui/dialog';
import { Button } from './ui/button';
import { Input } from './ui/input';
import { Label } from './ui/label';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from './ui/select';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { AuthContext } from '../../shared/context/auth-context';
import CampaignCard from './CampaignCard';

const NewCampaignModal = ({ isOpen, onClose, onCampaignCreated }) => {
  const [formData, setFormData] = useState({
    name: '',
    status: 'active',
    type: 'nurture',
    dateRange: '',
    owner: '',
    priority: false,
  });

  const { sendRequest } = useHttpClient();
  const auth = useContext(AuthContext);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation
    if (!formData.name || !formData.status || !formData.type || !formData.dateRange) {
      alert('Please fill out all required fields.');
      return;
    }

    try {
      await sendRequest(
        `${process.env.REACT_APP_API_URL}/campaigns/create`,
        'POST',
        JSON.stringify({
          ...formData,
          created_by: auth.user_id,
        }),
        {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`,
        }
      );
      onCampaignCreated();
      onClose();
    } catch (err) {
      console.error('Error creating campaign:', err);
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Create New Campaign</DialogTitle>
          <DialogDescription>Enter the details for your new campaign.</DialogDescription>
        </DialogHeader>
        <form onSubmit={handleSubmit}>
          <div className="grid gap-4 py-4">
            {/* Campaign Name */}
            <div className="grid gap-2">
              <Label htmlFor="name">Campaign Name</Label>
              <Input
                id="name"
                value={formData.name}
                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                required
              />
            </div>

            {/* Status Dropdown */}
            <div className="grid gap-2">
              <Label htmlFor="status">Status</Label>
              <Select
                value={formData.status}
                onValueChange={(value) => {
                  console.log("Status selected:", value); // Debugging log
                  setFormData({ ...formData, status: value });
                }}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Select status" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="active">Active</SelectItem>
                  <SelectItem value="draft">Draft</SelectItem>
                  <SelectItem value="completed">Completed</SelectItem>
                </SelectContent>
              </Select>
            </div>

            {/* Type Dropdown */}
            <div className="grid gap-2">
              <Label htmlFor="type">Type</Label>
              <Select
                value={formData.type}
                onValueChange={(value) => {
                  console.log("Type selected:", value); // Debugging log
                  setFormData({ ...formData, type: value });
                }}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Select type" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="nurture">Nurture</SelectItem>
                  <SelectItem value="acquisition">Acquisition</SelectItem>
                  <SelectItem value="retention">Retention</SelectItem>
                  <SelectItem value="awareness">Awareness</SelectItem>
                </SelectContent>
              </Select>
            </div>

            {/* Date Range */}
            <div className="grid gap-2">
              <Label htmlFor="dateRange">Date Range</Label>
              <Input
                id="dateRange"
                value={formData.dateRange}
                onChange={(e) => setFormData({ ...formData, dateRange: e.target.value })}
                placeholder="e.g., Jan 1 - Mar 31, 2024"
                required
              />
            </div>

            {/* Owner */}
            <div className="grid gap-2">
              <Label htmlFor="owner">Owner</Label>
              <Input
                id="owner"
                value={formData.owner}
                onChange={(e) => setFormData({ ...formData, owner: e.target.value })}
                placeholder="Enter owner name"
              />
            </div>

            {/* Priority Checkbox */}
            <div className="flex items-center space-x-2">
              <input
                type="checkbox"
                id="priority"
                checked={formData.priority}
                onChange={(e) => setFormData({ ...formData, priority: e.target.checked })}
                className="rounded border-gray-300"
              />
              <Label htmlFor="priority">Priority Campaign</Label>
            </div>
          </div>
          <DialogFooter>
            <Button type="submit">Create Campaign</Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};

const TeamCampaigns = () => {
  const [campaigns, setCampaigns] = useState([]);
  const [showNewCampaignModal, setShowNewCampaignModal] = useState(false);
  const [expandedCampaign, setExpandedCampaign] = useState(null);
  const { sendRequest } = useHttpClient();
  const auth = React.useContext(AuthContext);
  const userTeam = localStorage.getItem('team');

  const fetchTeamCampaigns = async () => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_API_URL}/users/all`,
        'GET',
        null,
        {
          Authorization: `Bearer ${auth.token}`,
        }
      );

      const teamUserIds = responseData
        .filter(user => user.team === userTeam)
        .map(user => user.user_id);

      const campaignPromises = teamUserIds.map(userId =>
        sendRequest(
          `${process.env.REACT_APP_API_URL}/campaigns/user/${userId}`,
          'GET',
          null,
          {
            Authorization: `Bearer ${auth.token}`,
          }
        )
      );

      const campaignResults = await Promise.all(campaignPromises);
      const allCampaigns = campaignResults.flat();
      
      const uniqueCampaigns = Array.from(new Set(allCampaigns.map(c => c.campaign_id)))
        .map(id => allCampaigns.find(c => c.campaign_id === id))
        .sort((a, b) => new Date(b.created_date) - new Date(a.created_date));

      setCampaigns(uniqueCampaigns);
    } catch (err) {
      console.error('Error fetching team campaigns:', err);
    }
  };

  useEffect(() => {
    fetchTeamCampaigns();
  }, [sendRequest, auth.token, userTeam]);

  const handleToggleExpand = (campaignId) => {
    setExpandedCampaign(prevId => prevId === campaignId ? null : campaignId);
  };

  return (
    <div className="p-8 pb-32"> {/* Added pb-32 for bottom padding */}
      <div className="flex justify-between items-center mb-6">
        <Button onClick={() => setShowNewCampaignModal(true)}>
          Create Campaign
        </Button>
      </div>

      <div className="space-y-4">
        {campaigns.map((campaign) => (
          <CampaignCard
            key={campaign.campaign_id}
            campaign={campaign}
            isExpanded={expandedCampaign === campaign.campaign_id}
            onToggleExpand={() => handleToggleExpand(campaign.campaign_id)}
          />
        ))}
      </div>

      <NewCampaignModal
        isOpen={showNewCampaignModal}
        onClose={() => setShowNewCampaignModal(false)}
        onCampaignCreated={fetchTeamCampaigns}
      />
    </div>
  );
};

export default TeamCampaigns;
