import React, { useContext, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import Users from "./user/pages/Users";
import NewLead from "./leads/pages/NewLead";
import UploadList from "./leads/pages/UploadList";
import APIIntegration from "./user/pages/APIIntegration";
import LandingPage from "./user/landingPage/LandingPage";
import UserLeads from "./leads/pages/UserLeads";
import AccountManagement from "./accounts/pages/AccountManagement";
import UserUploads from "./uploads/pages/UserUploads";
import ReportingDashboard from "./reporting/ReportingDashboard";
import QueueMapping from "./queue_mapping/pages/QueueMapping";
import Login from "./user/pages/Login";
import ResetPassword from "./user/pages/ResetPassword";
import MainNavigation from "./shared/components/Navigation/MainNavigation";
import UserSettings from "./user/pages/UserSettings";
import ZipCodeManagement from "./zip_codes/pages/ZipCodeManagement";
import Campaigns from "./campaigns/pages/Campaigns";
import AudienceBuilder from "./leads/pages/AudienceBuilder";
import TeamHub from "./teamhub/pages/TeamHub";
import TeamCampaigns from "./teamhub/components/TeamCampaigns";
import JoinTeam from "./teamhub/pages/JoinTeam";
import PrivateRoute from "./PrivateRoute";
import {
  AuthContextProvider,
  AuthContext,
} from "./shared/context/auth-context";
import { NotificationProvider } from "./shared/context/NotificationContext";
import TokenExpirationHandler from './shared/components/TokenExpirationHandler';
import { CssBaseline, Box } from "@mui/material";
import "./App.css";

const App = () => {
  const { token } = useContext(AuthContext);
  const auth = useContext(AuthContext);
  const location = useLocation();
  const pathsWithoutNav = ["/landing-page"];
  const history = useHistory();

  const handleLogout = () => {
    auth.logout();
    history.push('/');
   };
  
  const navigateToNewLead = () => history.push("/leads/new");
  const navigateToUserLeads = () => history.push(`/${auth.user_id}/leads`);
  const navigateToUploadList = () => history.push("/leads/uploadlist");
  const navigateToAccountManagement = () =>
    history.push("/accounts/management");
  const navigateToLeadFlow = () => history.push("/leadflow");
  const navigateToAPIIntegration = () => history.push("/api-integration");
  const navigateToZipCodeManagement = () =>
    history.push("/zip-code-management");
  const navigateToQueueMapping = () => history.push("/queue-mapping");
  const navigateToUserUploads = () => history.push(`/${auth.user_id}/uploads`);
  const navigateToAllUsers = () => history.push("/all-users");
  const navigateToUserSettings = () => history.push("/user-settings");

  useEffect(() => {
    const handleKeyDown = (event) => {
      
      if (event.altKey && event.key === 'C' || event.altKey && event.key === 'c') {
        navigateToNewLead();
      }
      if (event.altKey && event.key === 'u' || event.altKey && event.key === 'U') {
        navigateToUploadList();
      }
      if (event.altKey && event.key === 'L' || event.altKey && event.key === 'l') {
        handleLogout();
      }
      if (event.altKey && event.key === 'i' || event.altKey && event.key === 'I') {
        navigateToUserSettings();
      }
      if (event.altKey && event.key === 'm' || event.altKey && event.key === 'M') {
        navigateToAccountManagement()
      }
      if (event.altKey && event.key === 'n' || event.altKey && event.key === 'N') {
        navigateToAPIIntegration()
      }
      if (event.altKey && event.key === 'z' || event.altKey && event.key === 'Z') {
        navigateToZipCodeManagement()
      }
      if (event.altKey && event.key === 'b' || event.altKey && event.key === 'B') {
        navigateToAllUsers()
      }
      if (event.altKey && event.key === 'q' || event.altKey && event.key === 'Q') {
        navigateToQueueMapping()
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
   }, [history]);


  return (
    <>
      {!pathsWithoutNav.includes(location.pathname) && token && (
        <MainNavigation />
      )}
      <CssBaseline />
      <Box className="main-content">
        <Box className="scrollable-container">
          <Switch>
            <Route path="/reset-password" component={ResetPassword} />
            <Route path="/join-team" component={JoinTeam} />
            {token ? (
              <>
                <Route path="/all-users" exact component={Users} />
                <Route path="/:user_id/uploads" exact component={UserUploads} />
                <Route path="/:user_id/leads" exact component={UserLeads} />
                <Route path="/landing-page" exact component={LandingPage} />
                <Route path="/leads/new" exact component={NewLead} />
                <Route path="/leads/uploadlist" exact component={UploadList} />
                <Route path="/user-settings" exact component={UserSettings} />
                <PrivateRoute path="/accounts/management" exact component={AccountManagement} />
                <PrivateRoute path="/zip-code-management" exact component={ZipCodeManagement} />
                <PrivateRoute path="/queue-mapping" exact component={QueueMapping} />
                <PrivateRoute path="/api-integration" exact component={APIIntegration} />
                <PrivateRoute path="/leadflow" exact component={ReportingDashboard} />
                <PrivateRoute path="/audience-builder" exact component={AudienceBuilder} />
                <PrivateRoute path="/campaigns/track" exact component={Campaigns} />
                <PrivateRoute path="/team-hub/:tab?" component={TeamHub} />
                <Redirect to="/landing-page" />
              </>
            ) : (
              <>
                <Route path="/" exact component={Login} />
                <Route path="/auth" component={Login} />
                <Redirect to="/auth" />
              </>
            )}
          </Switch>
        </Box>
      </Box>
    </>
  );
};

const AppWithRouter = () => (
  <Router>
    <AuthContextProvider>
      <NotificationProvider> 
        <TokenExpirationHandler />
          <App />
      </NotificationProvider>
    </AuthContextProvider>
  </Router>
);

export default AppWithRouter;
