import React, { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  AlertTriangle,
  Camera,
  Check,
  Loader2,
  User,
  Bell,
} from "lucide-react";
import { Switch } from "../components/ui/switch";
import { AuthContext } from "../../shared/context/auth-context";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { useForm } from "../../shared/hooks/form-hook";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";

const UserSettings = () => {
  const auth = useContext(AuthContext);
  const history = useHistory();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [isLoadingInitialData, setIsLoadingInitialData] = useState(true);

  const [formData, setFormData] = useState({
    username: "",
    division: "",
    team: "",
    timeZone: "",
    imageUrl: null,
    emailPreferences: {
      systemAnnouncements: true,
      weeklyDigest: true,
      fileNotifications: true,
    },
  });

  const [isSaving, setIsSaving] = useState(false);
  const [saveStatus, setSaveStatus] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);

  const calculateProfileCompletion = () => {
    const fields = [
      {
        name: "Profile Photo",
        value: imagePreview || formData.imageUrl,
        weight: 20,
      },
      { name: "Username", value: formData.username, weight: 20 },
      { name: "Division", value: formData.division, weight: 20 },
      { name: "Team", value: formData.team, weight: 20 },
      { name: "Time Zone", value: formData.timeZone, weight: 20 },
    ];

    const completedWeight = fields.reduce((acc, field) => {
      return acc + (field.value ? field.weight : 0);
    }, 0);

    return {
      percentage: completedWeight,
      incomplete: fields.filter((field) => !field.value),
    };
  };

  const { percentage, incomplete } = calculateProfileCompletion();

  useEffect(() => {
    const trackPageVisit = async () => {
      if (auth && auth.user_id) {
        try {
          await sendRequest(
            `${process.env.REACT_APP_API_URL}/users/track-page-visit`,
            "POST",
            JSON.stringify({
              user_id: auth.user_id,
              page_name: "user_settings",
            }),
            {
              "Content-Type": "application/json",
              Authorization: `Bearer ${auth.token}`,
            },
          );
        } catch (err) {
          console.error("Error tracking page visit:", err);
        }
      }
    };
    trackPageVisit();
  }, [auth, sendRequest]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_API_URL}/users/${auth.user_id}`,
          "GET",
          null,
          {
            Authorization: `Bearer ${auth.token}`,
          },
        );

        let division = responseData.division || "";
        let team = responseData.team || "";

        if (team.includes(" - ")) {
          const [returnedDivision, returnedTeam] = team.split(" - ", 2);
          division = returnedDivision;
          team = returnedTeam;
        }

        setFormData({
          username: responseData.username || "",
          division: division,
          team: team,
          timeZone: responseData.time_zone || "",
          imageUrl: responseData.image_url || null,
          emailPreferences: {
            systemAnnouncements:
              responseData.email_preferences?.systemAnnouncements ?? true,
            weeklyDigest: responseData.email_preferences?.weeklyDigest ?? true,
            fileNotifications:
              responseData.email_preferences?.fileNotifications ?? true,
          },
        });

        if (responseData.image_url) {
          setImagePreview(responseData.image_url);
        }
      } catch (err) {
        console.error("Error fetching user data:", err);
      } finally {
        setIsLoadingInitialData(false);
      }
    };

    if (auth && auth.token) {
      fetchUserData();
    }
  }, [auth.user_id, auth.token, sendRequest]);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFormData((prev) => ({ ...prev, imageFile: file }));
      setImagePreview(URL.createObjectURL(file));
    }
  };

  const handleImageRemove = () => {
    setFormData((prev) => ({ ...prev, imageFile: null, imageUrl: null }));
    setImagePreview(null);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSaving(true);
    setSaveStatus(null);

    try {
      const formDataToSend = new FormData();
      formDataToSend.append("username", formData.username);
      formDataToSend.append("division", formData.division);

      const combinedTeamValue =
        formData.division && formData.team
          ? `${formData.division} - ${formData.team}`
          : "";
      formDataToSend.append("team", combinedTeamValue);

      formDataToSend.append("time_zone", formData.timeZone);
      formDataToSend.append(
        "email_preferences",
        JSON.stringify(formData.emailPreferences),
      );

      if (formData.imageFile) {
        formDataToSend.append("image", formData.imageFile);
      }

      await sendRequest(
        `${process.env.REACT_APP_API_URL}/users/${auth.user_id}`,
        "PATCH",
        formDataToSend,
        {
          Authorization: `Bearer ${auth.token}`,
        },
      );

      setSaveStatus("success");
      setTimeout(() => {
        history.push("/landing-page");
      }, 2000);
    } catch (err) {
      setSaveStatus("error");
      console.error("Error updating user:", err);
    } finally {
      setIsSaving(false);
    }
  };

  const handleNotificationToggle = (setting) => {
    setFormData((prev) => ({
      ...prev,
      emailPreferences: {
        ...(prev.emailPreferences || {}),
        [setting]: !prev.emailPreferences?.[setting],
      },
    }));
  };

  if (isLoadingInitialData) {
    return (
      <div className="min-h-screen bg-gray-50 py-8 pb-20">
        <div className="max-w-3xl mx-auto">
          <div className="bg-white px-8 py-6 rounded-lg shadow-sm space-y-6 animate-pulse">
            {/* Header Skeleton */}
            <div className="flex items-center justify-between">
              <div className="h-8 w-48 bg-gray-200 rounded" />
              <div className="h-8 w-32 bg-gray-200 rounded" />
            </div>

            {/* Progress Bar Skeleton */}
            <div className="w-full bg-gray-200 rounded-full h-2.5" />

            {/* Profile Photo Skeleton */}
            <div className="flex items-start space-x-6">
              <div className="h-24 w-24 rounded-full bg-gray-200" />
              <div className="space-y-3">
                <div className="h-6 w-32 bg-gray-200 rounded" />
                <div className="h-4 w-48 bg-gray-200 rounded" />
              </div>
            </div>

            {/* Form Fields Skeleton */}
            <div className="space-y-6">
              <div className="space-y-2">
                <div className="h-4 w-24 bg-gray-200 rounded" />
                <div className="h-10 w-full bg-gray-200 rounded" />
              </div>

              <div className="grid grid-cols-2 gap-6">
                <div className="space-y-2">
                  <div className="h-4 w-24 bg-gray-200 rounded" />
                  <div className="h-10 w-full bg-gray-200 rounded" />
                </div>
                <div className="space-y-2">
                  <div className="h-4 w-24 bg-gray-200 rounded" />
                  <div className="h-10 w-full bg-gray-200 rounded" />
                </div>
              </div>

              <div className="space-y-2">
                <div className="h-4 w-24 bg-gray-200 rounded" />
                <div className="h-10 w-full bg-gray-200 rounded" />
              </div>
            </div>

            {/* Email Preferences Skeleton */}
            <div className="space-y-4 border-t pt-6">
              <div className="flex items-center">
                <div className="h-5 w-5 bg-gray-200 rounded mr-2" />
                <div className="h-6 w-48 bg-gray-200 rounded" />
              </div>

              {[1, 2, 3].map((i) => (
                <div key={i} className="flex items-center justify-between">
                  <div className="space-y-2">
                    <div className="h-4 w-32 bg-gray-200 rounded" />
                    <div className="h-4 w-48 bg-gray-200 rounded" />
                  </div>
                  <div className="h-6 w-12 bg-gray-200 rounded" />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <ErrorModal error={error} onClear={clearError} />
      <div className="min-h-screen bg-gray-50 py-8 pb-20">
        <div className="max-w-3xl mx-auto">
          {/* Profile Completion Card */}
          <div className="bg-white px-8 py-6 rounded-t-lg border-b">
            <div className="flex items-center justify-between mb-4">
              <h1 className="text-2xl font-semibold text-gray-900">
                Profile Settings
              </h1>
              <div className="flex items-center space-x-2">
                <div className="text-sm text-gray-500">Profile Completion</div>
                <div className="font-medium text-lg text-blue-600">
                  {percentage}%
                </div>
              </div>
            </div>

            {/* Progress Bar */}
            <div className="w-full bg-gray-200 rounded-full h-2.5 mb-4">
              <div
                className="bg-blue-600 h-2.5 rounded-full transition-all duration-500 ease-out"
                style={{ width: `${percentage}%` }}
              />
            </div>

            {/* Incomplete Fields Warning */}
            {incomplete.length > 0 && (
              <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 mt-4">
                <div className="flex">
                  <AlertTriangle className="h-5 w-5 text-yellow-400" />
                  <div className="ml-3">
                    <h3 className="text-sm font-medium text-yellow-800">
                      Complete your profile to get the most out of the platform
                    </h3>
                    <div className="mt-2 text-sm text-yellow-700">
                      <p>Missing information:</p>
                      <ul className="list-disc list-inside">
                        {incomplete.map((field) => (
                          <li key={field.name}>{field.name}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          {/* Main Content */}
          <div className="bg-white px-8 py-6 rounded-b-lg shadow-sm">
            <form onSubmit={handleSubmit} className="space-y-6">
              {/* Profile Photo Section */}
              <div className="flex items-start space-x-6">
                <div className="relative">
                  {imagePreview ? (
                    <img
                      src={imagePreview}
                      alt="Profile"
                      className="h-24 w-24 rounded-full object-cover"
                    />
                  ) : (
                    <div className="h-24 w-24 rounded-full bg-gray-100 flex items-center justify-center">
                      <User className="h-12 w-12 text-gray-400" />
                    </div>
                  )}
                  <label
                    htmlFor="image-upload"
                    className="absolute bottom-0 right-0 rounded-full bg-blue-600 p-2 shadow-sm hover:bg-blue-700 transition-colors cursor-pointer"
                  >
                    <Camera className="h-4 w-4 text-white" />
                  </label>
                  <input
                    type="file"
                    id="image-upload"
                    className="hidden"
                    accept=".jpg,.png,.jpeg"
                    onChange={handleImageUpload}
                  />
                </div>
                <div className="space-y-1">
                  <h3 className="text-lg font-medium text-gray-900">
                    Profile Photo
                  </h3>
                  <p className="text-sm text-gray-500">
                    Upload a photo to help your team recognize you
                  </p>
                  <div className="flex gap-2">
                    <label
                      htmlFor="image-upload"
                      className="text-sm font-medium text-blue-600 hover:text-blue-700 cursor-pointer"
                    >
                      Upload new photo
                    </label>
                    {imagePreview && (
                      <button
                        type="button"
                        onClick={handleImageRemove}
                        className="text-sm font-medium text-red-600 hover:text-red-700"
                      >
                        Remove
                      </button>
                    )}
                  </div>
                </div>
              </div>

              {/* Form Fields */}
              <div className="grid grid-cols-1 gap-6 mt-6">
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Username
                  </label>
                  <input
                    type="text"
                    name="username"
                    value={formData.username}
                    onChange={handleInputChange}
                    className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 text-sm focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                    placeholder="Enter your username"
                  />
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Division
                    </label>
                    <select
                      name="division"
                      value={formData.division}
                      onChange={handleInputChange}
                      className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 text-sm focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                    >
                      <option value="">Select Division</option>
                      <option value="HQ">HQ</option>
                      <option value="Central">Central</option>
                      <option value="Northeast">Northeast</option>
                      <option value="West">West</option>
                    </select>
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Team
                    </label>
                    <select
                      name="team"
                      value={formData.team}
                      onChange={handleInputChange}
                      disabled={!formData.division}
                      className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 text-sm focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                    >
                      <option value="">Select Team</option>
                      <option value="Data Analytics & BI">
                        Data Analytics & BI
                      </option>
                      <option value="Division Marketing">
                        Division Marketing
                      </option>
                      <option value="Field Sales">Field Sales</option>
                      <option value="Growth Marketing">Growth Marketing</option>
                      <option value="Marketing Sciences & Technology">
                        Marketing Sciences & Technology
                      </option>
                      <option value="Region Marketing">Region Marketing</option>
                      <option value="Sales Operations">Sales Operations</option>
                    </select>
                  </div>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Time Zone
                  </label>
                  <select
                    name="timeZone"
                    value={formData.timeZone}
                    onChange={handleInputChange}
                    className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 text-sm focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                  >
                    <option value="">Select Time Zone</option>
                    <option value="America/New_York">Eastern Time</option>
                    <option value="America/Chicago">Central Time</option>
                    <option value="America/Denver">Mountain Time</option>
                    <option value="America/Los_Angeles">Pacific Time</option>
                    <option value="Asia/Kolkata">India Standard Time</option>
                  </select>
                </div>
              </div>

              {/* Email Notifications Section */}
              <div className="border-t pt-6">
                <div className="flex items-center mb-6">
                  <Bell className="h-5 w-5 text-gray-400 mr-2" />
                  <h2 className="text-lg font-medium text-gray-900">
                    Email Notifications
                  </h2>
                </div>

                <div className="space-y-4">
                  <div className="flex items-center justify-between">
                    <div className="space-y-0.5">
                      <label className="text-sm font-medium text-gray-900">
                        System Announcements
                      </label>
                      <p className="text-sm text-gray-500">
                        Receive updates about system changes and maintenance
                      </p>
                    </div>
                    <Switch
                      checked={
                        formData?.emailPreferences?.systemAnnouncements ?? true
                      }
                      onCheckedChange={() =>
                        handleNotificationToggle("systemAnnouncements")
                      }
                    />
                  </div>

                  <div className="flex items-center justify-between">
                    <div className="space-y-0.5">
                      <label className="text-sm font-medium text-gray-900">
                        Weekly Digest
                      </label>
                      <p className="text-sm text-gray-500">
                        Get a summary of important updates every week
                      </p>
                    </div>
                    <Switch
                      checked={formData?.emailPreferences?.weeklyDigest ?? true}
                      onCheckedChange={() =>
                        handleNotificationToggle("weeklyDigest")
                      }
                    />
                  </div>

                  <div className="flex items-center justify-between">
                    <div className="space-y-0.5">
                      <label className="text-sm font-medium text-gray-900">
                        File Ready Notifications
                      </label>
                      <p className="text-sm text-gray-500">
                        Be notified when your files are ready for download
                      </p>
                    </div>
                    <Switch
                      checked={
                        formData?.emailPreferences?.fileNotifications ?? true
                      }
                      onCheckedChange={() =>
                        handleNotificationToggle("fileNotifications")
                      }
                    />
                  </div>
                </div>
              </div>

              {/* Save Button and Status */}
              <div className="flex items-center justify-end space-x-4 pt-6">
                {saveStatus === "success" && (
                  <div className="flex items-center text-green-600 text-sm">
                    <Check className="h-4 w-4 mr-1" />
                    Changes saved successfully
                  </div>
                )}
                {saveStatus === "error" && (
                  <div className="flex items-center text-red-600 text-sm">
                    <AlertTriangle className="h-4 w-4 mr-1" />
                    Error saving changes
                  </div>
                )}

                <button
                  type="submit"
                  disabled={isSaving}
                  className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  {isSaving ? (
                    <>
                      <Loader2 className="animate-spin h-4 w-4 mr-2" />
                      Saving...
                    </>
                  ) : (
                    "Save Changes"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserSettings;
