import React, { useState, useContext, useEffect } from "react";
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  Modal,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Paper,
} from "@mui/material";
import { AuthContext } from "../../shared/context/auth-context";
import SetUp from "../components/steps/SetUp";
import FileUpload from "../components/steps/FileUpload";
import EnrichmentFieldSelector from "../components/steps/EnrichmentFieldSelector";
import MapFields from "../components/steps/MapFields";
import Review from "../components/steps/Review";
import Submit from "../components/steps/Submit";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Tooltip from "@mui/material/Tooltip";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import "react-toastify/dist/ReactToastify.css";

const getSteps = (enrichmentOnly) => {
  const baseSteps = ["Set Up", "File Upload", "Map Fields", "Review", "Submit"];
  if (enrichmentOnly) {
    return [
      "Set Up",
      "File Upload",
      "Select Enrichment Fields",
      "Map Fields",
      "Review",
      "Submit",
    ];
  }
  return baseSteps;
};

const expectedHeaders = [
  "business_name",
  "address",
  "address2",
  "city",
  "state_or_province",
  "zip_or_postal_code",
  "country",
  "website",
  "contact_first_name",
  "contact_last_name",
  "contact_job_title",
  "contact_phone",
  "contact_phone_extension",
  "contact_mobile",
  "contact_email",
  "contact_supplemental_email",
  "contact_linkedin_url",
  "number_of_employees",
  "products_of_interest",
  "hq_source_provided",
  "us_federal_agency",
  "lead_notes",
  "custom_questions",
  "asset_1",
  "asset_2",
  "asset_3",
  "campaign_name",
  "lead_source",
  "event_name",
];

const headerVariations = {
  business_name: [
    "business_name",
    "biz_name",
    "company_name",
    "company",
    "businessname",
    "organization",
    "orgname",
    "org name",
    "business",
    "organizationname",
    "organization name",
  ],
  address: [
    "address",
    "addr",
    "location",
    "address1",
    "streetaddress",
    "street address",
    "street",
    "addressline1",
    "addressline_1",
    "address 1",
    "address line 1",
  ],
  address2: [
    "address2",
    "address 2",
    "address_line_2",
    "addr2",
    "suite",
    "addressline2",
    "secondaryaddress",
    "secondary address",
  ],
  city: ["city", "town", "municipality", "locality", "place"],
  state_or_province: [
    "state",
    "province",
    "stateorprovince",
    "state or province",
    "state-or-province",
    "region",
    "territory",
    "statename",
    "state name",
  ],
  zip_or_postal_code: [
    "zip",
    "zipcode",
    "postal_code",
    "postalcode",
    "postal code",
    "zip code",
    "post code",
    "postcode",
  ],
  website: [
    "website",
    "webpage",
    "url",
    "site",
    "homepage",
    "webaddress",
    "websiteurl",
    "webpageurl",
    "siteurl",
    "homepageurl",
    "web",
    "webpageaddress",
    "webpageurl",
    "website url",
    "site url",
    "site address",
    "web address",
    "webpage address",
    "webpage url",
    "domain",
  ],
  country: [
    "country",
    "nation",
    "countryname",
    "country name",
    "nationname",
    "nation name",
  ],
  contact_first_name: [
    "first_name",
    "firstname",
    "fname",
    "first name",
    "given name",
    "givenname",
    "contact first name",
    "contactfirstname",
  ],
  contact_last_name: [
    "last_name",
    "lastname",
    "lname",
    "last name",
    "surname",
    "family name",
    "contact last name",
    "contactlastname",
  ],
  contact_job_title: [
    "job_title",
    "jobtitle",
    "title",
    "position",
    "role",
    "job role",
    "job position",
    "occupation",
  ],
  contact_phone: [
    "phone",
    "telephone",
    "phonenumber",
    "phone number",
    "contact phone",
    "contactphone",
    "work phone",
    "business phone",
  ],
  contact_phone_extension: [
    "extension",
    "ext",
    "phone_extension",
    "phoneext",
    "phone ext",
    "phone extension",
  ],
  contact_mobile: [
    "mobile",
    "cell",
    "cellphone",
    "mobile phone",
    "mobilephone",
    "cellular",
  ],
  contact_email: [
    "email",
    "emailaddress",
    "email address",
    "contact email",
    "contactemail",
    "work email",
  ],
  contact_supplemental_email: [
    "secondary_email",
    "alternate_email",
    "supplemental_email",
    "additional_email",
    "backup_email",
    "other_email",
  ],
  contact_linkedin_url: [
    "linkedin",
    "linkedinurl",
    "linkedin_url",
    "linkedin profile",
    "linkedin link",
  ],
  number_of_employees: [
    "employees",
    "employee_count",
    "company_size",
    "org_size",
    "headcount",
    "staff_count",
    "number_of_employees",
    "employeecount",
  ],
  products_of_interest: [
    "products",
    "interested_products",
    "product_interest",
    "interests",
    "products_of_interest",
    "interested_in",
  ],
  hq_source_provided: [
    "hq_source",
    "hqsource",
    "headquarters_source",
    "hq source",
    "headquarters source",
  ],
  us_federal_agency: [
    "federal_agency",
    "federalagency",
    "us_agency",
    "usagency",
    "federal agency",
  ],
  lead_notes: [
    "notes",
    "comments",
    "remarks",
    "additional_notes",
    "lead_notes",
    "leadnotes",
  ],
  custom_questions: [
    "questions",
    "custom_fields",
    "customfields",
    "custom_questions",
    "customquestions",
  ],
  asset_1: ["asset1", "asset_1", "file1", "file_1", "document1", "document_1"],
  asset_2: ["asset2", "asset_2", "file2", "file_2", "document2", "document_2"],
  asset_3: ["asset3", "asset_3", "file3", "file_3", "document3", "document_3"],
  campaign_name: [
    "campaign",
    "campaignname",
    "campaign_name",
    "marketing_campaign",
  ],
  lead_source: ["source", "leadsource", "lead_source", "origin", "lead_origin"],
  event_name: ["event", "eventname", "event_name", "conference", "meeting"],
};

// Define semantic groups for related concepts
const semanticGroups = {
  business: ["company", "organization", "business", "enterprise"],
  contact: ["person", "individual", "contact"],
  phone: ["telephone", "cell", "mobile", "number"],
  email: ["mail", "e-mail", "electronic mail"],
  address: ["location", "place", "street"],
};

const formatHeader = (header) => {
  return header
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

const findBestSemanticMatch = (
  normalizedHeader,
  headerVariations,
  mappedExpectedHeaders,
) => {
  // Check for exact matches first
  for (const [expectedHeader, variations] of Object.entries(headerVariations)) {
    if (normalizedHeader === expectedHeader.replace(/[^a-z0-9]/g, "")) {
      return expectedHeader;
    }
  }

  let bestMatch = null;
  let highestScore = 0;

  for (const [expectedHeader, variations] of Object.entries(headerVariations)) {
    // Skip if already mapped (except for lead_notes and custom_questions)
    if (
      mappedExpectedHeaders.has(expectedHeader) &&
      expectedHeader !== "lead_notes" &&
      expectedHeader !== "custom_questions"
    ) {
      continue;
    }

    // Calculate match score
    let score = 0;

    // Check for exact matches in variations
    for (const variation of variations) {
      const normalizedVariation = variation
        .toLowerCase()
        .replace(/[^a-z0-9]/g, "");
      if (normalizedHeader === normalizedVariation) {
        score = 1;
        break;
      }
    }

    // Check for partial matches if no exact match found
    if (score < 1) {
      for (const variation of variations) {
        const normalizedVariation = variation
          .toLowerCase()
          .replace(/[^a-z0-9]/g, "");
        if (
          normalizedHeader.includes(normalizedVariation) ||
          normalizedVariation.includes(normalizedHeader)
        ) {
          score = Math.max(score, 0.7);
        }
      }
    }

    // Check semantic groups
    for (const [groupKey, groupTerms] of Object.entries(semanticGroups)) {
      const headerHasGroupTerm = groupTerms.some((term) =>
        normalizedHeader.includes(term),
      );
      const expectedHasGroupTerm = groupTerms.some((term) =>
        expectedHeader.includes(term),
      );

      if (headerHasGroupTerm && expectedHasGroupTerm) {
        score = Math.max(score, 0.6);
      }
    }

    // Update best match if this is the highest score so far
    if (score > highestScore) {
      highestScore = score;
      bestMatch = expectedHeader;
    }
  }

  // Only return a match if we have high confidence
  return highestScore > 0.8 ? bestMatch : null;
};

const getMappedHeader = (uploadedHeader, mappedExpectedHeaders) => {
  const normalizedHeader = uploadedHeader
    .toLowerCase()
    .replace(/[^a-z0-9]/g, "");

  // First try exact matches
  for (const [expectedHeader, variations] of Object.entries(headerVariations)) {
    // Skip if already mapped (except for lead_notes and custom_questions)
    if (
      mappedExpectedHeaders.has(expectedHeader) &&
      expectedHeader !== "lead_notes" &&
      expectedHeader !== "custom_questions"
    ) {
      continue;
    }

    for (const variation of variations) {
      const normalizedVariation = variation
        .toLowerCase()
        .replace(/[^a-z0-9]/g, "");
      if (normalizedHeader === normalizedVariation) {
        return expectedHeader;
      }
    }
  }

  // If no exact match, try semantic matching
  const semanticMatch = findBestSemanticMatch(
    normalizedHeader,
    headerVariations,
    mappedExpectedHeaders,
  );
  if (semanticMatch) {
    return semanticMatch;
  }

  return "";
};

const UploadList = () => {
  // State declarations
  const [activeStep, setActiveStep] = useState(0);
  const [campaignName, setCampaignName] = useState("");
  const [leadSource, setLeadSource] = useState("");
  const [eventName, setEventName] = useState("");
  const [destinations, setDestinations] = useState({
    send_to_nurture: false,
    send_to_crm: false,
    enrichment_only: false,
  });
  const [file, setFile] = useState(null);
  const [fileData, setFileData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [headerMapping, setHeaderMapping] = useState({});
  const [needsHeaderMapping, setNeedsHeaderMapping] = useState(false);
  const [headerMappingDialogOpen, setHeaderMappingDialogOpen] = useState(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [uploadedHeaders, setUploadedHeaders] = useState([]);
  const [isMappingValid, setIsMappingValid] = useState(false);
  const [mappingsConfirmed, setMappingsConfirmed] = useState(false);
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [selectedEnrichmentFields, setSelectedEnrichmentFields] = useState([]);

  const auth = useContext(AuthContext);
  const history = useHistory();

  // Effect for initial header mapping
  useEffect(() => {
    if (fileData.length > 0) {
      const uploadedHeaders = Object.keys(fileData[0]);
      const mappedExpectedHeaders = new Set();
      const initialMapping = {};

      // First pass: Look for exact matches
      uploadedHeaders.forEach((header) => {
        const mappedHeader = getMappedHeader(header, mappedExpectedHeaders);

        if (mappedHeader !== "do_not_map") {
          initialMapping[header] = mappedHeader;

          // Only add to mapped set if it's not lead_notes or custom_questions
          if (
            mappedHeader !== "lead_notes" &&
            mappedHeader !== "custom_questions"
          ) {
            mappedExpectedHeaders.add(mappedHeader);
          }
        } else {
          initialMapping[header] = "do_not_map";
        }
      });

      setHeaderMapping(initialMapping);
      setUploadedHeaders(uploadedHeaders);
      setMappingsConfirmed(true);
      setNeedsHeaderMapping(false);
    }
  }, [fileData]);

  // Upload chunk function
  const uploadChunk = async (chunk, chunkIndex, totalChunks, fileId) => {
    const chunkFormData = new FormData();
    const headers = Object.values(headerMapping).join(",");
    const rows = chunk
      .map((row) => {
        return Object.keys(headerMapping)
          .map((key) => `"${(row[key] || "").toString().replace(/"/g, '""')}"`)
          .join(",");
      })
      .join("\n");

    const csvContent = `${headers}\n${rows}`;
    const blob = new Blob([csvContent], { type: "text/csv" });

    chunkFormData.append("file", blob, `chunk_${chunkIndex}.csv`);
    chunkFormData.append("file_id", fileId || "");
    chunkFormData.append("chunk_index", chunkIndex);
    chunkFormData.append("total_chunks", totalChunks);
    chunkFormData.append("created_by", auth.user_id);
    chunkFormData.append("campaign_name", campaignName);
    chunkFormData.append("file_lead_source", leadSource);
    chunkFormData.append("event_name", eventName);
    chunkFormData.append("file_name", file.name);

    if (chunkIndex === 0) {
      chunkFormData.append(
        "enrichment_fields",
        JSON.stringify(selectedEnrichmentFields),
      );
      const mappedFields = Object.values(headerMapping).filter(
        (header) => header !== "do_not_map",
      );
      chunkFormData.append("mapped_fields", JSON.stringify(mappedFields));
    }

    Object.keys(destinations).forEach((key) => {
      chunkFormData.append(key, destinations[key]);
    });

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/leads/uploadChunk`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
          body: chunkFormData,
        },
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || "Upload failed");
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Upload chunk error:", error);
      throw error;
    }
  };

  const completeFileUpload = async (fileId) => {
    if (!fileId) {
      throw new Error("No file ID provided");
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/leads/completeFileUpload`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth.token}`,
          },
          body: JSON.stringify({ file_id: fileId }),
        },
      );

      const data = await response.json();
      console.log("Complete file upload response:", data);
      return data;
    } catch (error) {
      console.error("Error completing file upload:", error);
      throw error;
    }
  };

  const handleSubmit = async () => {
    setIsUploading(true);
    setProgress(0);
    let fileId = null;

    try {
      const chunks = chunkArray(fileData, 100);

      for (let i = 0; i < chunks.length; i++) {
        const chunk = chunks[i];
        const data = await uploadChunk(chunk, i, chunks.length, fileId);

        if (i === 0) {
          if (!data.file_id) {
            throw new Error("No file ID received from server");
          }
          fileId = data.file_id;
        }

        const progressValue = ((i + 1) / chunks.length) * 100;
        setProgress(progressValue);
      }

      if (fileId) {
        await completeFileUpload(fileId);
        setIsSubmitSuccess(true);
        setSuccessModalOpen(true);
      } else {
        throw new Error("No file ID available to complete upload");
      }
    } catch (error) {
      console.error("Upload error:", error);
      setErrorModalOpen(true);
    } finally {
      setIsUploading(false);
    }
  };

  const chunkArray = (array, size) => {
    const result = [];
    for (let i = 0; i < array.length; i += size) {
      result.push(array.slice(i, i + size));
    }
    return result;
  };

  const canProceedToNextStep = () => {
    const currentSteps = getSteps(destinations.enrichment_only);
    const stepName = currentSteps[activeStep];

    switch (stepName) {
      case "Set Up":
        return (
          destinations.send_to_nurture ||
          destinations.send_to_crm ||
          destinations.enrichment_only
        );
      case "File Upload":
        return !!file;
      case "Select Enrichment Fields":
        return (
          Array.isArray(selectedEnrichmentFields) &&
          selectedEnrichmentFields.length > 0
        );
      case "Map Fields":
        return (!needsHeaderMapping || mappingsConfirmed) && isMappingValid;
      case "Review":
        return true;
      case "Submit":
        return false;
      default:
        return false;
    }
  };

  const handleNext = () => {
    const currentSteps = getSteps(destinations.enrichment_only);
    if (activeStep === currentSteps.length - 1) {
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    const currentSteps = getSteps(destinations.enrichment_only);
    const stepName = currentSteps[activeStep];

    if (stepName === "Review" && !needsHeaderMapping) {
      setActiveStep((prevActiveStep) => prevActiveStep - 2);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const handleCloseModal = () => {
    history.push("/landing-page");
  };

  const handleCancelMapping = () => {
    setFileData([]);
    setFile(null);
    setHeaderMapping({});
    setUploadedHeaders([]);
    setConfirmationDialogOpen(false);
    setHeaderMappingDialogOpen(false);
    setMappingsConfirmed(false);
  };

  const renderConfirmationDialog = () => (
    <Dialog
      open={confirmationDialogOpen}
      onClose={() => setConfirmationDialogOpen(false)}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>Confirm Mappings</DialogTitle>
      <DialogContent>
        <Typography variant="body1" gutterBottom>
          Please review the mappings below. If something is incorrect, you can
          remap the headers.
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Uploaded Header</TableCell>
              <TableCell>Mapped To</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(headerMapping).map((uploadedHeader, index) => (
              <TableRow key={index}>
                <TableCell>{formatHeader(uploadedHeader)}</TableCell>
                <TableCell>
                  {formatHeader(headerMapping[uploadedHeader])}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleCancelMapping()} color="secondary">
          Cancel
        </Button>
        <Button
          onClick={() => {
            setHeaderMapping({});
            setConfirmationDialogOpen(false);
            setHeaderMappingDialogOpen(true);
          }}
          color="primary"
        >
          Remap
        </Button>
        <Button
          onClick={() => {
            if (isMappingValid) {
              setMappingsConfirmed(true);
              setConfirmationDialogOpen(false);
            }
          }}
          color="primary"
          disabled={!isMappingValid}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );

  const getStepContent = (step) => {
    // Get total number of steps based on whether enrichment is selected
    const currentSteps = getSteps(destinations.enrichment_only);

    switch (currentSteps[step]) {
      case "Set Up":
        return (
          <SetUp
            campaignName={campaignName}
            setCampaignName={setCampaignName}
            leadSource={leadSource}
            setLeadSource={setLeadSource}
            eventName={eventName}
            setEventName={setEventName}
            destinations={destinations}
            setDestinations={setDestinations}
          />
        );
      case "File Upload":
        return (
          <FileUpload
            file={file}
            setFile={setFile}
            setFileData={setFileData}
            expectedHeaders={expectedHeaders}
          />
        );
      case "Select Enrichment Fields":
        return (
          <EnrichmentFieldSelector
            onFieldsChange={(fields) => {
              setSelectedEnrichmentFields(fields);
            }}
            defaultFields={[
              "business_id",
              "business_legal_name",
              "cmcst_status_cb",
              "mktg_segment",
              "mktg_subsegment",
              "contact_id",
              "cmcst_division",
              "cmcst_region",
              "hq_division",
              "hq_region",
            ]}
          />
        );
      case "Map Fields":
        return (
          <MapFields
            fileData={fileData}
            headerMapping={headerMapping}
            setHeaderMapping={setHeaderMapping}
            expectedHeaders={expectedHeaders}
            onValidityChange={setIsMappingValid}
            uploadedHeaders={uploadedHeaders}
          />
        );
      case "Review":
        return <Review fileData={fileData} headerMapping={headerMapping} />;
      case "Submit":
        return (
          <Submit
            fileData={fileData}
            headerMapping={headerMapping}
            campaignName={campaignName}
            leadSource={leadSource}
            eventName={eventName}
            destinations={destinations}
            setProgress={setProgress}
            setIsSubmitSuccess={setIsSubmitSuccess}
            auth={auth}
            file={file}
            onSubmit={handleSubmit}
            isUploading={isUploading}
            selectedEnrichmentFields={selectedEnrichmentFields}
          />
        );
      default:
        return "Unknown step";
    }
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        style={{
          zIndex: 10001,
          top: "80px",
          ".Toastify__toast": {
            backgroundColor: "white",
            opacity: "1 !important",
          },
        }}
      />
      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="upload-modal-title"
        aria-describedby="upload-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "95%",
            maxWidth: 1200,
            maxHeight: "90vh",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Stepper activeStep={activeStep} alternativeLabel>
            {getSteps(destinations.enrichment_only).map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <Box sx={{ mt: 4, mb: 2, flexGrow: 1, overflow: "auto" }}>
            {activeStep === getSteps(destinations.enrichment_only).length ? (
              <Typography>All steps completed</Typography>
            ) : (
              <>{getStepContent(activeStep)}</>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              pt: 2,
              mt: 3,
              borderTop: "1px solid rgba(0, 0, 0, 0.12)",
            }}
          >
            {activeStep > 0 && (
              <Button color="inherit" onClick={handleBack} sx={{ mr: 1 }}>
                Back
              </Button>
            )}
            <Box sx={{ flex: "1 1 auto" }} />
            {activeStep === getSteps(destinations.enrichment_only).length - 1 &&
              progress > 0 && (
                <Box sx={{ display: "flex", alignItems: "center", mr: 2 }}>
                  <CircularProgress
                    variant="determinate"
                    value={progress}
                    size={24}
                    sx={{ mr: 1 }}
                  />
                  <Typography variant="body2" color="text.secondary">
                    {`${Math.round(progress)}%`}
                  </Typography>
                </Box>
              )}
            {activeStep < getSteps(destinations.enrichment_only).length - 1 && (
              <Button
                onClick={handleNext}
                disabled={!canProceedToNextStep()}
                variant="contained"
              >
                Next
              </Button>
            )}
          </Box>
        </Box>
      </Modal>
      <Dialog
        open={successModalOpen}
        onClose={() => setSuccessModalOpen(false)}
      >
        <DialogTitle>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{ gap: 1 }}
          >
            <Typography variant="h6" component="div">
              Success
            </Typography>
            <CheckCircleIcon sx={{ color: "#4caf50", fontSize: 30 }} />
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box textAlign="center" mt={2}>
            <Typography
              variant="body1"
              sx={{ fontSize: "1.1rem", color: "#333" }}
            >
              Your file has been successfully uploaded! Processing will complete
              in the background.
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center", mt: 2, mb: 1 }}>
          <Button
            onClick={() => {
              setSuccessModalOpen(false);
              history.push("/landing-page");
            }}
            variant="contained"
            sx={{
              backgroundColor: "#1976d2",
              color: "white",
              padding: "8px 20px",
              "&:hover": { backgroundColor: "#1565c0" },
            }}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
      {renderConfirmationDialog()}
    </>
  );
};

export default UploadList;
