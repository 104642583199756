import React, { useEffect, useContext, useState } from "react";
import {
  Box,
  Grid,
  Container,
  Card,
  CardContent,
  Typography,
  CssBaseline,
  Button,
  IconButton,
  Tooltip,
  Fade,
  Chip,
  Popper,
  TextField,
  InputAdornment,
  Divider,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { format, utcToZonedTime } from "date-fns-tz";
import CloseIcon from "@mui/icons-material/Close";
import AddBoxIcon from "@mui/icons-material/AddBox";
import ListIcon from "@mui/icons-material/List";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import BusinessIcon from "@mui/icons-material/Business";
import ApiIcon from "@mui/icons-material/Api";
import AltRouteIcon from "@mui/icons-material/AltRoute";
import RouteIcon from "@mui/icons-material/Route";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import FolderSpecialIcon from "@mui/icons-material/FolderSpecial";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import GroupsIcon from "@mui/icons-material/Groups";
import SearchIcon from "@mui/icons-material/Search";
import ProfileCompletionBanner from "../components/ProfileCompletionBanner";
import Autocomplete from "@mui/material/Autocomplete";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../shared/context/auth-context";
import { useHttpClient } from "../../shared/hooks/http-hook";
import MainFooter from "../../shared/components/Navigation/MainFooter";
import MainNavigation from "../../shared/components/Navigation/MainNavigation";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";

const theme = createTheme({
  typography: {
    h4: {
      fontWeight: 800,
      color: "#1a237e",
    },
    h6: {
      fontWeight: 600,
      color: "#1a237e",
    },
    body1: {
      color: "#424242",
    },
    body2: {
      color: "#424242",
    },
  },
  palette: {
    primary: {
      main: "#1976d2",
      light: "#42a5f5",
      dark: "#1565c0",
    },
    secondary: {
      main: "#f50057",
    },
    background: {
      default: "#f5f7fa",
    },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          transition: "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
        },
      },
    },
  },
});

const LandingPage = () => {
  const history = useHistory();
  const auth = useContext(AuthContext);
  const { sendRequest: sendTrackingRequest } = useHttpClient();
  const [greeting, setGreeting] = useState("Good day");
  const [showWelcomeBanner, setShowWelcomeBanner] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [lastActivity, setLastActivity] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [showCompletionBanner, setShowCompletionBanner] = useState(true);
  const [userData, setUserData] = useState({
    division: localStorage.getItem("division") || "",
    team: localStorage.getItem("team") || "",
    timeZone: localStorage.getItem("time_zone") || "",
  });

  const [selectedOption, setSelectedOption] = useState(null);

  const handleLogout = () => {
    auth.logout();
    history.push("/");
  };

  const trackPageVisit = async (pageName) => {
    try {
      await sendTrackingRequest(
        process.env.REACT_APP_API_URL + "/users/track-page-visit",
        "POST",
        JSON.stringify({
          user_id: auth.user_id,
          page_name: pageName,
        }),
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.token}`,
        },
      );
    } catch (error) {
      console.error("Error tracking page visit:", error);
    }
  };

  const navigateToNewLead = () => history.push("/leads/new");
  const navigateToUserLeads = () => history.push(`/${auth.user_id}/leads`);
  const navigateToUploadList = () => history.push("/leads/uploadlist");
  const navigateToAccountManagement = () =>
    history.push("/accounts/management");
  const navigateToLeadFlow = () => history.push("/leadflow");
  const navigateToAPIIntegration = () => history.push("/api-integration");
  const navigateToZipCodeManagement = () =>
    history.push("/zip-code-management");
  const navigateToQueueMapping = () => history.push("/queue-mapping");
  const navigateToUserUploads = () => history.push(`/${auth.user_id}/uploads`);
  const navigateToAllUsers = () => history.push("/all-users");
  const navigateToUserSettings = () => history.push("/user-settings");

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (
        (event.altKey && event.key === "C") ||
        (event.altKey && event.key === "c")
      ) {
        navigateToNewLead();
      }
      if (
        (event.altKey && event.key === "u") ||
        (event.altKey && event.key === "U")
      ) {
        navigateToUploadList();
      }
      if (
        (event.altKey && event.key === "L") ||
        (event.altKey && event.key === "l")
      ) {
        handleLogout();
      }
      if (
        (event.altKey && event.key === "i") ||
        (event.altKey && event.key === "I")
      ) {
        navigateToUserSettings();
      }
      if (
        (event.altKey && event.key === "m") ||
        (event.altKey && event.key === "M")
      ) {
        navigateToAccountManagement();
      }
      if (
        (event.altKey && event.key === "n") ||
        (event.altKey && event.key === "N")
      ) {
        navigateToAPIIntegration();
      }
      if (
        (event.altKey && event.key === "z") ||
        (event.altKey && event.key === "Z")
      ) {
        navigateToZipCodeManagement();
      }
      if (
        (event.altKey && event.key === "b") ||
        (event.altKey && event.key === "B")
      ) {
        navigateToAllUsers();
      }
      if (
        (event.altKey && event.key === "q") ||
        (event.altKey && event.key === "Q")
      ) {
        navigateToQueueMapping();
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [history]);

  useEffect(() => {
    const fetchUserData = async () => {
      if (auth && auth.user_id) {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/users/${auth.user_id}`,
            {
              headers: {
                Authorization: `Bearer ${auth.token}`,
              },
            },
          );
          const data = await response.json();

          // Update state
          setUserData({
            division: data.division || "",
            team: data.team || "",
            timeZone: data.time_zone || "",
          });

          // Update localStorage
          localStorage.setItem("division", data.division || "");
          localStorage.setItem("team", data.team || "");
          localStorage.setItem("time_zone", data.time_zone || "");
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      }
    };

    fetchUserData();
  }, [auth.user_id, auth.token]);

  useEffect(() => {
    if (auth && auth.user_id) {
      trackPageVisit("landing_page");
    }
  }, [auth]);

  useEffect(() => {
    const checkDataLoaded = () => {
      const role = auth.role;
      const username = localStorage.getItem("username");
      const showBanner = localStorage.getItem("show_welcome_banner");

      if (role && username && showBanner !== null) {
        const firstName = username.split(" ")[0];
        const userTimeZone =
          localStorage.getItem("time_zone") ||
          Intl.DateTimeFormat().resolvedOptions().timeZone;
        const now = utcToZonedTime(new Date(), userTimeZone);
        const hour = format(now, "HH");

        const holidays = [
          { date: "10-31", greeting: "Happy Halloween" },
          { date: "12-25", greeting: "Merry Christmas" },
          { date: "01-01", greeting: "Happy New Year" },
          { date: "07-04", greeting: "Happy Independence Day" },
        ];

        const today = format(now, "MM-dd");
        const holiday = holidays.find((h) => h.date === today);

        if (holiday) {
          setGreeting(`${holiday.greeting}, ${firstName}.`);
        } else {
          let timeBasedGreeting = "Good day";
          if (hour >= 5 && hour < 12) {
            timeBasedGreeting = "Good morning";
          } else if (hour >= 12 && hour < 18) {
            timeBasedGreeting = "Good afternoon";
          } else {
            timeBasedGreeting = "Good evening";
          }

          setGreeting(`${timeBasedGreeting}, ${firstName}.`);
        }
        setIsLoading(false);
      }
    };

    const timeoutId = setTimeout(() => {
      if (!auth.role) {
        window.location.reload();
      } else {
        checkDataLoaded();
      }
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [auth.role]);

  useEffect(() => {
    const showBanner = localStorage.getItem("show_welcome_banner");
    setShowWelcomeBanner(showBanner === "true");
  }, []);

  const dismissBanner = async () => {
    try {
      await fetch(
        `${process.env.REACT_APP_API_URL}/users/${auth.user_id}/dismiss-welcome-banner`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${auth.token}`,
            "Content-Type": "application/json",
          },
        },
      );
      localStorage.setItem("show_welcome_banner", "false");
      setShowWelcomeBanner(false);
    } catch (error) {
      console.error("Failed to dismiss welcome banner:", error);
    }
  };

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <LoadingSpinner />
      </Box>
    );
  }

  const getFilteredCards = () => {
    // Base cards available to all users
    let baseCards = [
      {
        icon: (
          <AddBoxIcon
            sx={{ fontSize: 40, color: theme.palette.primary.main }}
          />
        ),
        title: "Create New Lead",
        description:
          "Easily add new potential customers with detailed information and customizable fields.",
        onClick: navigateToNewLead,
        category: "actions",
      },
      {
        icon: (
          <CloudUploadIcon
            sx={{ fontSize: 40, color: theme.palette.primary.main }}
          />
        ),
        title: "Upload Leads",
        description:
          "Quickly import multiple leads into leadfeed using simple file uploads for efficient data integration.",
        onClick: navigateToUploadList,
        category: "actions",
      },
      {
        icon: (
          <ListIcon sx={{ fontSize: 40, color: theme.palette.primary.main }} />
        ),
        title: "My Leads",
        description:
          "Access and manage your personal list of leads with comprehensive tracking and follow-up options.",
        onClick: navigateToUserLeads,
        category: "activity",
      },
      {
        icon: (
          <FileCopyIcon
            sx={{ fontSize: 40, color: theme.palette.primary.main }}
          />
        ),
        title: "My Uploads",
        description:
          "View and manage your previous lead uploads, check enrichment status, and download enriched files.",
        onClick: navigateToUserUploads,
        category: "activity",
      },
    ];

    // Admin-only cards
    const adminCards = [
      {
        icon: (
          <BusinessIcon
            sx={{ fontSize: 40, color: theme.palette.primary.main }}
          />
        ),
        title: "Account Management",
        description:
          "Manage named account ownership and settings for a streamlined experience.",
        onClick: navigateToAccountManagement,
        category: "admin",
      },
      {
        icon: (
          <AltRouteIcon
            sx={{ fontSize: 40, color: theme.palette.primary.main }}
          />
        ),
        title: "Zip Code Routing",
        description:
          "Efficiently manage your lead zip code queues & owners for better lead flow management.",
        onClick: navigateToZipCodeManagement,
        category: "admin",
      },
      {
        icon: (
          <RouteIcon sx={{ fontSize: 40, color: theme.palette.primary.main }} />
        ),
        title: "CRM Queue Mapping",
        description: "Map and manage CRM queues for leads routing.",
        onClick: navigateToQueueMapping,
        category: "admin",
      },
      {
        icon: (
          <ApiIcon sx={{ fontSize: 40, color: theme.palette.primary.main }} />
        ),
        title: "API Integration",
        description:
          "Enables seamless data exchange and enhanced functionality.",
        onClick: navigateToAPIIntegration,
        category: "admin",
      },
      {
        icon: (
          <AccountBoxIcon
            sx={{ fontSize: 40, color: theme.palette.primary.main }}
          />
        ),
        title: "All Users",
        description: "View and manage all users of leadfeed.",
        onClick: navigateToAllUsers,
        category: "admin",
      },
      {
        icon: (
          <LeaderboardIcon
            sx={{ fontSize: 40, color: theme.palette.primary.main }}
          />
        ),
        title: "Audience Builder",
        description:
          "Build and manage targeted audience segments for marketing campaigns.",
        onClick: () => history.push("/audience-builder"),
        category: "admin",
      },
      {
        icon: (
          <FolderSpecialIcon
            sx={{ fontSize: 40, color: theme.palette.primary.main }}
          />
        ),
        title: "Campaign Track",
        description:
          "Monitor and manage your marketing campaigns with detailed analytics and tracking.",
        onClick: () => history.push("/campaigns/track"),
        category: "admin",
      },
      {
        icon: (
          <GroupsIcon
            sx={{ fontSize: 40, color: theme.palette.primary.main }}
          />
        ),
        title: "My Team",
        description:
          "Manage your team members, view team performance, and coordinate activities.",
        onClick: () => history.push("/team-hub"),
        category: "admin",
      },
    ];

    // Combine cards based on user role
    let allCards =
      auth.role === "admin" ? [...baseCards, ...adminCards] : baseCards;

    // Apply search filter
    if (searchTerm) {
      allCards = allCards.filter(
        (card) =>
          card.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
          card.description.toLowerCase().includes(searchTerm.toLowerCase()),
      );
    }

    // Apply category filter
    if (selectedCategory !== "all") {
      allCards = allCards.filter((card) => card.category === selectedCategory);
    }

    return allCards;
  };

  const featureCards = getFilteredCards();

  const autocompleteOptions = featureCards.map((card) => ({
    title: card.title,
    description: card.description,
    icon: card.icon,
    onClick: card.onClick,
  }));

  // Categories based on role
  const categories = [
    { id: "all", label: "All Features" },
    { id: "actions", label: "Quick Actions" },
    { id: "activity", label: "My Activity" },
    ...(auth.role === "admin"
      ? [{ id: "admin", label: "Administration" }]
      : []),
  ];

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
          justifyContent: "flex-start",
          bgcolor: "background.default",
        }}
      >
        <MainNavigation />
        <Container
          maxWidth="xl"
          sx={{
            mt: { xs: 8, sm: 4 },
            mb: 16,
            px: { xs: 2, sm: 3, md: 4 },
            position: "relative",
            zIndex: 0,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 4,
              flexWrap: "wrap",
              gap: 2,
            }}
          >
            <Typography
              variant="h4"
              sx={{
                fontSize: { xs: "1.8rem", sm: "2.2rem", md: "2.5rem" },
                background: "linear-gradient(90deg, #42a5f5, #1976d2)",
                backgroundClip: "text",
                color: "transparent",
                fontWeight: 800,
                textAlign: "center",
              }}
            >
              {greeting}
            </Typography>
            <Autocomplete
              options={searchTerm ? autocompleteOptions : []}
              getOptionLabel={(option) => option.title}
              onChange={(event, newValue) => {
                if (newValue && newValue.onClick) {
                  newValue.onClick();
                }
              }}
              inputValue={searchTerm}
              onInputChange={(event, newInputValue) =>
                setSearchTerm(newInputValue)
              }
              renderOption={(props, option) => (
                <Box
                  {...props}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                    p: 1,
                  }}
                >
                  {React.cloneElement(option.icon, {
                    fontSize: "small",
                    sx: { fontSize: 20 },
                  })}
                  <Box>
                    <Typography variant="body1" sx={{ fontWeight: 600 }}>
                      {option.title}
                    </Typography>
                  </Box>
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Search features..."
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon sx={{ color: "gray" }} />
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    background: "#ffffff",
                    borderRadius: "40px",
                    boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "20px",
                      padding: "4px 12px",
                    },
                    "& .MuiInputBase-input": {
                      fontSize: "1rem",
                    },
                  }}
                />
              )}
              PopperComponent={(props) => (
                <Popper
                  {...props}
                  sx={{
                    width: "300px !important", // Match the dropdown's width with the search bar
                    zIndex: 1, // Ensure it overlays correctly
                  }}
                />
              )}
              sx={{
                width: "300px",
                minHeight: "40px",
                "& .MuiAutocomplete-inputRoot": {
                  padding: "0",
                },
              }}
            />

            {/* Profile Completion Banner */}
            {showCompletionBanner && (
              <ProfileCompletionBanner
                username={localStorage.getItem("username")}
                division={userData.division}
                team={userData.team}
                timeZone={userData.timeZone}
                onDismiss={() => setShowCompletionBanner(false)}
                onNavigate={() => history.push("/user-settings")}
              />
            )}
          </Box>

          {/* Category Filter Chips */}
          <Box sx={{ mb: 4, display: "flex", gap: 1, flexWrap: "wrap" }}>
            {categories.map((category) => (
              <Chip
                key={category.id}
                label={category.label}
                onClick={() => setSelectedCategory(category.id)}
                sx={{
                  backgroundColor:
                    selectedCategory === category.id ? "#1976d2" : "#fff",
                  color: selectedCategory === category.id ? "#fff" : "#1976d2",
                  "&:hover": {
                    backgroundColor:
                      selectedCategory === category.id ? "#1565c0" : "#f5f5f5",
                  },
                  fontWeight: 500,
                  boxShadow: "0 2px 4px rgba(0,0,0,0.05)",
                }}
              />
            ))}
          </Box>

          {/* Welcome Banner */}
          {showWelcomeBanner && (
            <Fade in={showWelcomeBanner}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  background: "linear-gradient(90deg, #3b82f6, #06b6d4)",
                  color: "#fff",
                  p: 2,
                  borderRadius: "10px",
                  mb: 3,
                  animation: "fadeIn 1s ease-in",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{ fontWeight: 500, fontSize: "1.2rem", color: "#fff" }}
                >
                  FINISH SETTING UP YOUR PROFILE
                </Typography>
                <Box display="flex" alignItems="center">
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#1e3a8a",
                      borderRadius: "20px",
                      mr: 2,
                      "&:hover": {
                        backgroundColor: "#22d3ee",
                      },
                    }}
                    onClick={navigateToUserSettings}
                  >
                    CLICK HERE
                  </Button>
                  <IconButton sx={{ color: "#fff" }} onClick={dismissBanner}>
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Box>
            </Fade>
          )}

          {/* Feature Cards Grid */}
          <Grid container spacing={3}>
            {getFilteredCards().map((feature, index) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                <Tooltip
                  title={feature.onClick ? "Click to access" : "Coming soon"}
                  arrow
                  placement="top"
                >
                  <Card
                    variant="outlined"
                    sx={{
                      height: "100%",
                      minHeight: 200,
                      borderRadius: "16px",
                      border: "none",
                      bgcolor: feature.onClick
                        ? "#fff"
                        : "rgba(255, 255, 255, 0.7)",
                      backdropFilter: "blur(10px)",
                      boxShadow: feature.onClick
                        ? "0 4px 20px rgba(0, 0, 0, 0.05)"
                        : "none",
                      transition: "all 0.3s ease",
                      cursor: feature.onClick ? "pointer" : "default",
                      opacity: feature.onClick ? 1 : 0.7,
                      "&:hover": feature.onClick
                        ? {
                            transform: "translateY(-4px)",
                            boxShadow: "0 12px 28px rgba(0, 0, 0, 0.1)",
                          }
                        : {},
                    }}
                    onClick={feature.onClick}
                  >
                    <CardContent
                      sx={{
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                        p: 3,
                        position: "relative",
                      }}
                    >
                      {/* Category Label */}
                      <Typography
                        variant="caption"
                        sx={{
                          position: "absolute",
                          top: 8,
                          right: 8,
                          color: "#666",
                          fontSize: "0.7rem",
                          textTransform: "uppercase",
                          letterSpacing: "0.5px",
                        }}
                      >
                        {
                          categories.find((cat) => cat.id === feature.category)
                            ?.label
                        }
                      </Typography>
                      <Box
                        sx={{
                          mb: 2,
                          p: 1.5,
                          borderRadius: "12px",
                          bgcolor: feature.onClick
                            ? "rgba(25, 118, 210, 0.05)"
                            : "transparent",
                        }}
                      >
                        {feature.icon}
                      </Box>
                      <Typography
                        variant="h6"
                        sx={{
                          mb: 1,
                          fontSize: "1.1rem",
                          fontWeight: 600,
                          color: feature.onClick ? "#1a237e" : "#757575",
                        }}
                      >
                        {feature.title}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          color: feature.onClick ? "#424242" : "#757575",
                          lineHeight: 1.6,
                        }}
                      >
                        {feature.description}
                      </Typography>
                    </CardContent>
                  </Card>
                </Tooltip>
              </Grid>
            ))}
          </Grid>
        </Container>
        <MainFooter />
      </Box>
    </ThemeProvider>
  );
};

export default LandingPage;
