import React, { useEffect, useState, useCallback, useContext } from "react";
import { useHttpClient } from "../../shared/hooks/http-hook";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import {
  DataGrid,
  GridActionsCellItem,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  GridRowModes,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import Portal from "@mui/material/Portal";
import Box from "@mui/material/Box";
import { Delete as DeleteIcon } from "@mui/icons-material";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import { v4 as uuidv4 } from "uuid";
import { AuthContext } from "../../shared/context/auth-context";
import "./ZipCodeManagement.css";

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: "none",
          backgroundColor: "#ffffff",
        },
        columnHeaders: {
          backgroundColor: "#f5f5f5",
          color: "#000",
          fontWeight: "bold",
        },
        columnHeaderTitle: {
          fontSize: "16px",
        },
        cell: {
          borderBottom: "1px solid #ddd",
        },
        row: {
          "&:hover": {
            backgroundColor: "#e3f2fd",
          },
        },
        footerContainer: {
          backgroundColor: "#f1f3f4",
        },
      },
    },
  },
});

const calculateColumnWidth = (headerName) => {
  const baseWidth = 50; // base width for padding and margins
  const charWidth = 10; // approximate width of each character
  return baseWidth + headerName.length * charWidth;
};

const CustomPagination = () => {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, (state) => state.pagination.page);
  const pageCount = useGridSelector(
    apiRef,
    (state) => state.pagination.pageCount,
  );

  return (
    <Pagination
      color="primary"
      count={pageCount}
      page={page + 1}
      renderItem={(props) => <PaginationItem {...props} disableRipple />}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
};

const CustomToolbar = ({ setRows, setRowModesModel }) => {
  const apiRef = useGridApiContext();

  const handleClick = () => {
    const newZipCodeId = uuidv4();
    const newZipCode = "00000"; // Temporary unique value

    setRows((oldRows) => [
      {
        zip_code_id: newZipCodeId,
        zip_code: newZipCode,
        division: "",
        region: "",
        sfdc_sb_queue_id: "",
        sfdc_sb_queue_name: "",
        sfdc_ent_queue_id: "",
        sfdc_ent_queue_name: "",
        sfdc_owner_id_ent: "",
        sfdc_owner_name_ent: "",
        sfdc_owner_email_ent: "",
        dynamics_sb_queue_id: "",
        dynamics_sb_queue_name: "",
        user_created: true,
        isNew: true,
      },
      ...oldRows,
    ]);

    setRowModesModel((oldModel) => ({
      ...oldModel,
      [newZipCodeId]: { mode: GridRowModes.Edit, fieldToFocus: "zip_code" },
    }));

    // Navigate to the first page to ensure the new row is visible
    apiRef.current.setPage(0);
  };

  return (
    <React.Fragment>
      <Portal container={() => document.getElementById("filter-panel")}>
        <GridToolbarQuickFilter />
      </Portal>
      <GridToolbarContainer>
        <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
          Add Record
        </Button>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
      </GridToolbarContainer>
    </React.Fragment>
  );
};

const ZipCodeManagement = () => {
  const { error, sendRequest, clearError } = useHttpClient();
  const [zipCodes, setZipCodes] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const auth = useContext(AuthContext);

  const trackPageVisit = async (pageName) => {
    try {
      await sendRequest(
        process.env.REACT_APP_API_URL + "/users/track-page-visit",
        "POST",
        JSON.stringify({
          user_id: auth.user_id,
          page_name: pageName,
        }),
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.token}`,
        },
      );
    } catch (error) {
      console.error("Error tracking page visit:", error);
    }
  };

  useEffect(() => {
    if (auth && auth.user_id) {
      trackPageVisit("zip_code_management");
    }
  }, [auth]);

  useEffect(() => {
    const fetchZipCodes = async () => {
      setIsLoading(true);
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_API_URL}/zip_codes/all`,
          "GET",
          null,
          {
            Authorization: `Bearer ${auth.token}`,
          },
        );
        setZipCodes(responseData);
      } catch (err) {
        console.error("Error fetching zip codes:", err);
      }
      setIsLoading(false);
    };
    if (auth && auth.token) {
      fetchZipCodes();
    }
  }, [sendRequest, auth]);

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const processRowUpdate = async (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setZipCodes((prevRows) =>
      prevRows.map((row) =>
        row.zip_code_id === newRow.zip_code_id ? updatedRow : row,
      ),
    );

    try {
      if (newRow.isNew) {
        await sendRequest(
          `${process.env.REACT_APP_API_URL}/zip_codes/create`,
          "POST",
          JSON.stringify(updatedRow),
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth.token}`,
          },
        );
      } else {
        await sendRequest(
          `${process.env.REACT_APP_API_URL}/zip_codes/${newRow.zip_code_id}`,
          "PATCH",
          JSON.stringify(updatedRow),
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth.token}`,
          },
        );
      }
    } catch (err) {
      console.error("Error updating zip code:", err);
    }

    return updatedRow;
  };

  const handleCellEditCommit = useCallback(
    async ({ id, field, value }) => {
      try {
        const updatedZipCode = { [field]: value };
        await sendRequest(
          `${process.env.REACT_APP_API_URL}/zip_codes/${id}`,
          "PATCH",
          JSON.stringify(updatedZipCode),
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth.token}`,
          },
        );
      } catch (err) {
        console.error("Error editing cell:", err);
      }
    },
    [sendRequest, auth],
  );

  const deleteZipCodeHandler = async (id) => {
    const confirmation = window.prompt("Type 'delete' to confirm:");
    if (confirmation === "delete") {
      try {
        await sendRequest(
          `${process.env.REACT_APP_API_URL}/zip_codes/${id}`,
          "DELETE",
          null,
          {
            Authorization: `Bearer ${auth.token}`,
          },
        );
        setZipCodes((prevZipCodes) =>
          prevZipCodes.filter((zip) => zip.zip_code_id !== id),
        );
      } catch (err) {
        console.error("Error deleting zip code:", err);
      }
    }
  };

  const columns = [
    { field: "zip_code_id", headerName: "ID", hide: true },
    {
      field: "zip_code",
      headerName: "Zip Code",
      width: calculateColumnWidth("Zip Code"),
      editable: true,
    },
    {
      field: "division",
      headerName: "Division",
      width: calculateColumnWidth("Division"),
      editable: true,
    },
    {
      field: "region",
      headerName: "Region",
      width: 250,
      editable: true,
    },
    {
      field: "sfdc_sb_queue_id",
      headerName: "SFDC SB Queue ID",
      width: calculateColumnWidth("SFDC SB Queue ID"),
      editable: true,
    },
    {
      field: "sfdc_sb_queue_name",
      headerName: "SFDC SB Queue Name",
      width: 250,
      editable: true,
    },
    {
      field: "sfdc_ent_queue_id",
      headerName: "SFDC ENT Queue ID",
      width: calculateColumnWidth("SFDC ENT Queue ID"),
      editable: true,
    },
    {
      field: "sfdc_ent_queue_name",
      headerName: "SFDC ENT Queue Name",
      width: 250,
      editable: true,
    },
    {
      field: "sfdc_owner_id_ent",
      headerName: "SFDC Owner ID ENT",
      width: calculateColumnWidth("SFDC Owner ID ENT"),
      editable: true,
    },
    {
      field: "sfdc_owner_name_ent",
      headerName: "SFDC Owner Name ENT",
      width: calculateColumnWidth("SFDC Owner Name ENT"),
      editable: true,
    },
    {
      field: "sfdc_owner_email_ent",
      headerName: "SFDC Owner Email ENT",
      width: calculateColumnWidth("SFDC Owner Email ENT"),
      editable: true,
    },
    {
      field: "dynamics_sb_queue_id",
      headerName: "Dynamics SB Queue ID",
      width: calculateColumnWidth("Dynamics SB Queue ID"),
      editable: true,
    },
    {
      field: "dynamics_sb_queue_name",
      headerName: "Dynamics SB Queue Name",
      width: 250,
      editable: true,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: calculateColumnWidth("Actions"),
      sortable: false,
      renderCell: (params) => (
        <>
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={() => deleteZipCodeHandler(params.id)}
          />
        </>
      ),
    },
  ];

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      {isLoading && (
        <div className="center">
          <LoadingSpinner />
        </div>
      )}
      {!isLoading && zipCodes && (
        <ThemeProvider theme={theme}>
          <div className="zip-code-management-container">
            <Box id="filter-panel" />
            <div className="zip-code-management-grid">
              <DataGrid
                rows={zipCodes}
                columns={columns}
                getRowId={(row) => row.zip_code_id}
                pageSize={100}
                pagination
                components={{
                  Toolbar: CustomToolbar,
                  Pagination: CustomPagination,
                }}
                componentsProps={{
                  toolbar: { setRows: setZipCodes, setRowModesModel },
                }}
                disableSelectionOnClick
                onCellEditCommit={handleCellEditCommit}
                density="compact"
                processRowUpdate={processRowUpdate}
                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                experimentalFeatures={{ newEditingApi: true }}
                initialState={{
                  filter: {
                    filterModel: {
                      items: [],
                      quickFilterExcludeHiddenColumns: true,
                    },
                  },
                }}
              />
            </div>
          </div>
        </ThemeProvider>
      )}
    </React.Fragment>
  );
};

export default ZipCodeManagement;
