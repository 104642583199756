import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Eye, EyeOff, Mail, Lock, User, Building } from "lucide-react";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import SuccessModal from "../../shared/components/UIElements/SuccessModal";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import image from "./leadfeed_login.png";

const Login = () => {
  const auth = useContext(AuthContext);
  const history = useHistory();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [showPassword, setShowPassword] = useState(false);
  const [isSignUp, setIsSignUp] = useState(false);
  const [formErrors, setFormErrors] = useState({
    name: "",
    company: "",
    email: "",
    password: "",
  });
  const [formState, setFormState] = useState({
    email: "",
    password: "",
    name: "",
    company: "",
  });
  const [rememberMe, setRememberMe] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState("");
  const [isForgotPasswordOpen, setIsForgotPasswordOpen] = useState(false);

  const validatePassword = (password) => ({
    length: password.length >= 8,
    uppercase: /[A-Z]/.test(password),
    lowercase: /[a-z]/.test(password),
    number: /\d/.test(password),
    special: /[!@#$%^&*(),.?":{}|<>]/.test(password),
  });

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const getColor = (isValid) => {
    if (isValid) return "text-green-500";
    if (!formState.password) return "text-gray-500";
    return "text-red-500";
  };

  useEffect(() => {
    const savedEmail = localStorage.getItem("rememberedEmail");
    const savedPassword = localStorage.getItem("rememberedPassword");
    if (savedEmail && savedPassword) {
      setFormState((prevState) => ({
        ...prevState,
        email: savedEmail,
        password: savedPassword,
      }));
      setRememberMe(true);
    }
  }, []);

  const handleInputChange = (e) => {
    const { id, value, checked } = e.target;
    if (id === "rememberMe") {
      setRememberMe(checked);
    } else {
      setFormState({
        ...formState,
        [id]: value,
      });
    }
  };

  const handleSignUpSubmit = async (e) => {
    e.preventDefault();
    const errors = {};
    if (!formState.name) errors.name = "Please enter your name.";
    if (!formState.company) errors.company = "Please enter your company name.";
    if (!formState.email || !validateEmail(formState.email)) {
      errors.email = "Please enter a valid email address.";
    }

    const passwordChecks = validatePassword(formState.password);
    if (Object.values(passwordChecks).some((check) => !check)) {
      errors.password = "Please ensure password meets all requirements.";
    }

    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      try {
        await sendRequest(
          process.env.REACT_APP_API_URL + "/users/signup",
          "POST",
          JSON.stringify({
            email: formState.email,
            password: formState.password,
            username: formState.name,
            company: formState.company,
          }),
          { "Content-Type": "application/json" },
        );
        setSuccessMessage(
          "Your signup has been submitted. You will be emailed once your account has been activated.",
        );
      } catch (err) {
        // Error handling is managed by ErrorModal
      }
    }
  };

  const handleSignInSubmit = async (e) => {
    e.preventDefault();
    const errors = {};
    if (!formState.email || !validateEmail(formState.email)) {
      errors.email = "Please enter a valid email address.";
    }
    if (!formState.password) {
      errors.password = "Please enter your password.";
    }

    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_API_URL + "/users/login",
          "POST",
          JSON.stringify({
            email: formState.email,
            password: formState.password,
          }),
          { "Content-Type": "application/json" },
        );
        auth.login(
          responseData.user_id,
          responseData.token,
          responseData.role,
          responseData.username,
          responseData.team,
          responseData.division,
          responseData.time_zone,
          responseData.show_welcome_banner,
        );

        if (rememberMe) {
          localStorage.setItem("rememberedEmail", formState.email);
          localStorage.setItem("rememberedPassword", formState.password);
        } else {
          localStorage.removeItem("rememberedEmail");
          localStorage.removeItem("rememberedPassword");
        }

        history.push("/landing-page");
      } catch (err) {
        // Error handling is managed by ErrorModal
      }
    }
  };

  const handleForgotPasswordOpen = () => {
    setIsForgotPasswordOpen(true);
  };

  const handleForgotPasswordClose = () => {
    setIsForgotPasswordOpen(false);
  };

  const forgotPasswordHandler = async () => {
    try {
      await sendRequest(
        process.env.REACT_APP_API_URL + "/users/password-reset-request",
        "POST",
        JSON.stringify({ email: forgotPasswordEmail }),
        { "Content-Type": "application/json" },
      );
      setSuccessMessage(
        "Please check your email for password reset instructions.",
      );
      setIsForgotPasswordOpen(false);
    } catch (err) {
      // Error handling is managed by ErrorModal
    }
  };

  const forgotPasswordInputHandler = (event) => {
    setForgotPasswordEmail(event.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      if (isSignUp) {
        handleSignUpSubmit(e);
      } else {
        handleSignInSubmit(e);
      }
    }
  };

  const renderPasswordInput = () => {
    const passwordChecks = validatePassword(formState.password);

    return (
      <div className="space-y-2">
        <div className="relative">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <Lock className="h-5 w-5 text-gray-400" />
          </div>
          <input
            type={showPassword ? "text" : "password"}
            id="password"
            placeholder="Password"
            className={`w-full pl-10 pr-12 py-2 border rounded-lg bg-white/50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${
              formErrors.password ? "border-red-500" : "border-gray-300"
            }`}
            value={formState.password}
            onChange={handleInputChange}
            onKeyPress={handleKeyPress}
          />
          <button
            type="button"
            onClick={() => setShowPassword(!showPassword)}
            className="absolute inset-y-0 right-0 pr-3 flex items-center"
          >
            {showPassword ? (
              <EyeOff className="h-5 w-5 text-gray-400" />
            ) : (
              <Eye className="h-5 w-5 text-gray-400" />
            )}
          </button>
        </div>

        {isSignUp && (
          <div className="text-sm space-y-1 px-2">
            <p className={getColor(passwordChecks.length)}>
              ✓ Minimum 8 characters
            </p>
            <p className={getColor(passwordChecks.uppercase)}>
              ✓ One uppercase letter
            </p>
            <p className={getColor(passwordChecks.lowercase)}>
              ✓ One lowercase letter
            </p>
            <p className={getColor(passwordChecks.number)}>✓ One number</p>
            <p className={getColor(passwordChecks.special)}>
              ✓ One special character
            </p>
          </div>
        )}

        {formErrors.password && (
          <p className="mt-1 text-sm text-red-500">{formErrors.password}</p>
        )}
      </div>
    );
  };

  return (
    <div id="root" className="login-page">
      <div className="min-h-screen w-full bg-gradient-to-br from-blue-50 via-white to-blue-50 relative overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <div className="absolute -top-1/2 -left-1/2 w-full h-full bg-blue-100/20 rounded-full blur-3xl" />
          <div className="absolute -bottom-1/2 -right-1/2 w-full h-full bg-blue-100/20 rounded-full blur-3xl" />
        </div>

        <div className="relative min-h-screen flex items-center justify-center">
          <div className="w-full max-w-5xl bg-white/70 backdrop-blur-lg rounded-2xl shadow-2xl overflow-hidden border border-white/50">
            <div className="flex flex-col md:flex-row">
              {isSignUp ? (
                <>
                  <div className="hidden md:block w-1/2">
                    <img
                      src={image}
                      alt="Leadfeed"
                      className="w-full h-full object-cover"
                    />
                  </div>
                  <div className="w-full md:w-1/2 p-8 space-y-6">
                    <div className="text-center mb-8">
                      <h1 className="text-3xl font-bold text-gray-800">
                        Create Account
                      </h1>
                      <p className="text-gray-600 mt-2">Join Leadfeed today!</p>
                    </div>

                    <div className="space-y-4">
                      <div className="relative">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                          <User className="h-5 w-5 text-gray-400" />
                        </div>
                        <input
                          type="text"
                          id="name"
                          placeholder="Your Name"
                          className={`w-full pl-10 pr-4 py-2 border rounded-lg bg-white/50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${
                            formErrors.name
                              ? "border-red-500"
                              : "border-gray-300"
                          }`}
                          value={formState.name}
                          onChange={handleInputChange}
                          onKeyPress={handleKeyPress}
                        />
                        {formErrors.name && (
                          <p className="mt-1 text-sm text-red-500">
                            {formErrors.name}
                          </p>
                        )}
                      </div>

                      <div className="relative">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                          <Building className="h-5 w-5 text-gray-400" />
                        </div>
                        <input
                          type="text"
                          id="company"
                          placeholder="Company Name"
                          className={`w-full pl-10 pr-4 py-2 border rounded-lg bg-white/50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${
                            formErrors.company
                              ? "border-red-500"
                              : "border-gray-300"
                          }`}
                          value={formState.company}
                          onChange={handleInputChange}
                          onKeyPress={handleKeyPress}
                        />
                        {formErrors.company && (
                          <p className="mt-1 text-sm text-red-500">
                            {formErrors.company}
                          </p>
                        )}
                      </div>

                      <div className="relative">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                          <Mail className="h-5 w-5 text-gray-400" />
                        </div>
                        <input
                          type="email"
                          id="email"
                          placeholder="Email"
                          className={`w-full pl-10 pr-4 py-2 border rounded-lg bg-white/50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${
                            formErrors.email
                              ? "border-red-500"
                              : "border-gray-300"
                          }`}
                          value={formState.email}
                          onChange={handleInputChange}
                          onKeyPress={handleKeyPress}
                        />
                        {formErrors.email && (
                          <p className="mt-1 text-sm text-red-500">
                            {formErrors.email}
                          </p>
                        )}
                      </div>

                      {renderPasswordInput()}
                    </div>

                    <button
                      onClick={handleSignUpSubmit}
                      className="w-full bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded-lg text-lg font-medium transition-colors duration-200"
                    >
                      Sign Up
                    </button>

                    <div className="text-center text-gray-600">
                      Already have an account?{" "}
                      <button
                        onClick={() => setIsSignUp(false)}
                        className="text-blue-600 hover:text-blue-700"
                      >
                        Sign In
                      </button>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="w-full md:w-1/2 p-8 space-y-6">
                    <div className="text-center mb-8">
                      <h1 className="text-3xl font-bold text-gray-800">
                        Welcome to Leadfeed
                      </h1>
                      <p className="text-gray-600 mt-2">Sign in to continue</p>
                    </div>

                    <div className="space-y-4">
                      <div className="relative">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                          <Mail className="h-5 w-5 text-gray-400" />
                        </div>
                        <input
                          type="email"
                          id="email"
                          placeholder="Email"
                          className={`w-full pl-10 pr-4 py-2 border rounded-lg bg-white/50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${
                            formErrors.email
                              ? "border-red-500"
                              : "border-gray-300"
                          }`}
                          value={formState.email}
                          onChange={handleInputChange}
                          onKeyPress={handleKeyPress}
                        />
                        {formErrors.email && (
                          <p className="mt-1 text-sm text-red-500">
                            {formErrors.email}
                          </p>
                        )}
                      </div>

                      {renderPasswordInput()}

                      <div className="flex items-center justify-between text-sm">
                        <div className="flex items-center">
                          <input
                            type="checkbox"
                            id="rememberMe"
                            checked={rememberMe}
                            onChange={handleInputChange}
                            className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                          />
                          <label
                            htmlFor="rememberMe"
                            className="ml-2 text-gray-600"
                          >
                            Remember me
                          </label>
                        </div>
                        <button
                          onClick={handleForgotPasswordOpen}
                          className="text-blue-600 hover:text-blue-700"
                        >
                          Forgot Password?
                        </button>
                      </div>
                    </div>

                    <button
                      onClick={handleSignInSubmit}
                      className="w-full bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded-lg text-lg font-medium transition-colors duration-200"
                    >
                      Sign In
                    </button>

                    <div className="text-center text-gray-600">
                      Don't have an account?{" "}
                      <button
                        onClick={() => setIsSignUp(true)}
                        className="text-blue-600 hover:text-blue-700"
                      >
                        Sign Up
                      </button>
                    </div>
                  </div>

                  <div className="hidden md:block w-1/2">
                    <img
                      src={image}
                      alt="leadfeed"
                      className="w-full h-full object-cover"
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        {/* Forgot Password Dialog */}
        {isForgotPasswordOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
            <div className="bg-white rounded-lg p-6 w-full max-w-md">
              <h2 className="text-xl font-bold mb-4">Forgot Password</h2>
              <input
                type="email"
                placeholder="Email Address"
                className="w-full p-2 border border-gray-300 rounded mb-4"
                value={forgotPasswordEmail}
                onChange={forgotPasswordInputHandler}
              />
              <div className="flex justify-end space-x-2">
                <button
                  onClick={handleForgotPasswordClose}
                  className="px-4 py-2 text-gray-600 hover:text-gray-800"
                >
                  Cancel
                </button>
                <button
                  onClick={forgotPasswordHandler}
                  className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        )}

        {/* Error and Success Modals */}
        <ErrorModal error={error} onClear={clearError} />
        {successMessage && (
          <SuccessModal
            success={successMessage}
            onClear={() => setSuccessMessage(null)}
          />
        )}
        {isLoading && <LoadingSpinner asOverlay />}
      </div>
    </div>
  );
};

export default Login;
