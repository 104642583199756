import React, { useState, useEffect, useContext } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Eye, EyeOff, Mail, Lock, User, Building, Users } from "lucide-react";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import image from "../../user/pages/leadfeed_login.png";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const JoinTeam = () => {
  const query = useQuery();
  const history = useHistory();
  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [teamDetails, setTeamDetails] = useState(null);
  const [isExistingUser, setIsExistingUser] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [formState, setFormState] = useState({
    email: "",
    password: "",
    name: "",
    company: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [emailEntered, setEmailEntered] = useState(false);
  const token = query.get("token");

  const validatePassword = (password) => ({
    length: password.length >= 8,
    uppercase: /[A-Z]/.test(password),
    lowercase: /[a-z]/.test(password),
    number: /\d/.test(password),
    special: /[!@#$%^&*(),.?":{}|<>]/.test(password),
  });

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormState({
      ...formState,
      [id]: value,
    });
  };

  useEffect(() => {
    const verifyToken = async () => {
      try {
        const response = await sendRequest(
          `${process.env.REACT_APP_API_URL}/users/team/verify-invite`,
          "POST",
          JSON.stringify({ token }),
          { "Content-Type": "application/json" }
        );
        setTeamDetails(response);
      } catch (err) {
        console.error(err);
      }
    };

    if (token) {
      verifyToken();
    }
  }, [token, sendRequest]);

  const handleEmailSubmit = async () => {
    if (!validateEmail(formState.email)) {
      setFormErrors({ email: "Please enter a valid email address." });
      return;
    }

    setFormErrors({});
    setEmailEntered(true);

    try {
      const emailCheckResponse = await sendRequest(
        `${process.env.REACT_APP_API_URL}/users/check-email`,
        "POST",
        JSON.stringify({ email: formState.email }),
        { "Content-Type": "application/json" }
      );

      setIsExistingUser(emailCheckResponse.exists);
    } catch (err) {
      console.error(err);
    }
  };

  const parseTeamAndDivision = (teamName) => {
    const parts = teamName.split(" - ");
    return {
      division: parts[0],
      team: parts.length > 1 ? parts[1] : teamName,
    };
  };

  const handleExistingUserLogin = async (e) => {
    e.preventDefault();
    try {
      // First login
      const loginResponse = await sendRequest(
        `${process.env.REACT_APP_API_URL}/users/login`,
        "POST",
        JSON.stringify({
          email: formState.email,
          password: formState.password,
        }),
        { "Content-Type": "application/json" }
      );

      // Then join team
      await sendRequest(
        `${process.env.REACT_APP_API_URL}/users/team/join`,
        "POST",
        JSON.stringify({ token }),
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${loginResponse.token}`,
        }
      );

      // Parse team and division
      const { team, division } = parseTeamAndDivision(teamDetails.team);

      // Login and redirect
      auth.login(
        loginResponse.user_id,
        loginResponse.token,
        loginResponse.role,
        loginResponse.username,
        `${division} - ${team}`, // combined
        division,
        loginResponse.time_zone,
        loginResponse.show_welcome_banner
      );
      history.push("/team-hub");
    } catch (err) {
      console.error(err);
    }
  };

  const handleNewUserJoin = async (e) => {
    e.preventDefault();

    // Validate form
    const errors = {};
    if (!formState.name) errors.name = "Please enter your name.";
    if (!formState.company) errors.company = "Please enter your company name.";
    if (!formState.email || !validateEmail(formState.email)) {
      errors.email = "Please enter a valid email address.";
    }

    const passwordChecks = validatePassword(formState.password);
    if (Object.values(passwordChecks).some((check) => !check)) {
      errors.password = "Please ensure password meets all requirements.";
    }

    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      try {
        const { division, team } = parseTeamAndDivision(teamDetails.team);

        // Create user account with team details
        await sendRequest(
          `${process.env.REACT_APP_API_URL}/users/signup`,
          "POST",
          JSON.stringify({
            email: formState.email,
            password: formState.password,
            username: formState.name,
            company: formState.company,
            team: `${division} - ${team}`, // combined string
            division: division,
            team_role: teamDetails.team_role,
            token: token,
          }),
          { "Content-Type": "application/json" }
        );

        // Redirect to login page with success message
        history.push("/auth");
      } catch (err) {
        console.error(err);
      }
    }
  };

  const getColor = (isValid) => {
    if (isValid) return "text-green-500";
    if (!formState.password) return "text-gray-500";
    return "text-red-500";
  };

  const passwordChecks = validatePassword(formState.password);

  return (
    <div className="login-page">
      <div className="min-h-screen w-full bg-gradient-to-br from-blue-50 via-white to-blue-50 relative overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <div className="absolute -top-1/2 -left-1/2 w-full h-full bg-blue-100/20 rounded-full blur-3xl" />
          <div className="absolute -bottom-1/2 -right-1/2 w-full h-full bg-blue-100/20 rounded-full blur-3xl" />
        </div>

        <div className="relative min-h-screen flex items-center justify-center p-4">
          <div className="w-full max-w-5xl bg-white/70 backdrop-blur-lg rounded-2xl shadow-2xl overflow-hidden border border-white/50">
            <div className="flex flex-col md:flex-row">
              <div className="w-full md:w-1/2 p-8 space-y-6">
                <div className="text-center mb-8">
                  <Users size={48} className="mx-auto mb-4 text-blue-600" />
                  {teamDetails ? (
                    <>
                      <h1 className="text-3xl font-bold text-gray-800">
                        Join Team
                      </h1>
                      <div className="mt-2">
                        <p className="text-gray-600">
                          You've been invited to join
                        </p>
                        <p className="text-xl font-semibold text-blue-600 mt-1">
                          {teamDetails.team}
                        </p>
                        <p className="text-sm text-gray-500 mt-1">
                          as a{" "}
                          {teamDetails.team_role === "team-admin"
                            ? "Team Admin"
                            : "Team Member"}
                        </p>
                      </div>
                    </>
                  ) : (
                    <h1 className="text-3xl font-bold text-gray-800">
                      Verifying Invite...
                    </h1>
                  )}
                </div>

                {isLoading && <LoadingSpinner asOverlay />}
                <ErrorModal error={error} onClear={clearError} />

                {!teamDetails ? (
                  <p>Loading team details...</p>
                ) : (
                  <>
                    {!emailEntered ? (
                      // Prompt user for email
                      <div className="space-y-4">
                        <div className="relative">
                          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <Mail className="h-5 w-5 text-gray-400" />
                          </div>
                          <input
                            type="email"
                            id="email"
                            placeholder="Email"
                            className={`w-full pl-10 pr-4 py-2 border rounded-lg bg-white/50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${
                              formErrors.email
                                ? "border-red-500"
                                : "border-gray-300"
                            }`}
                            value={formState.email}
                            onChange={handleInputChange}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                handleEmailSubmit();
                              }
                            }}  
                          />
                          {formErrors.email && (
                            <p className="mt-1 text-sm text-red-500">
                              {formErrors.email}
                            </p>
                          )}
                        </div>
                        <button
                          onClick={handleEmailSubmit}
                          className="w-full bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded-lg text-lg font-medium transition-colors duration-200"
                        >
                          Continue
                        </button>
                      </div>
                    ) : isExistingUser === null ? (
                      <p>Checking your email...</p>
                    ) : isExistingUser ? (
                      // Existing user: Show login form
                      <div className="space-y-4">
                        <div className="relative">
                          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <Lock className="h-5 w-5 text-gray-400" />
                          </div>
                          <input
                            type={showPassword ? "text" : "password"}
                            id="password"
                            placeholder="Password"
                            className="w-full pl-10 pr-12 py-2 border rounded-lg bg-white/50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                            value={formState.password}
                            onChange={handleInputChange}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                handleExistingUserLogin(e);
                              }
                            }}
                          />
                          <button
                            type="button"
                            onClick={() => setShowPassword(!showPassword)}
                            className="absolute inset-y-0 right-0 pr-3 flex items-center"
                          >
                            {showPassword ? (
                              <EyeOff className="h-5 w-5 text-gray-400" />
                            ) : (
                              <Eye className="h-5 w-5 text-gray-400" />
                            )}
                          </button>
                        </div>

                        <button
                          onClick={handleExistingUserLogin}
                          className="w-full bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded-lg text-lg font-medium transition-colors duration-200"
                        >
                          Sign In & Join Team
                        </button>
                      </div>
                    ) : (
                      // New user: Show signup form
                      <div className="space-y-4">
                        <div className="relative">
                          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <User className="h-5 w-5 text-gray-400" />
                          </div>
                          <input
                            type="text"
                            id="name"
                            placeholder="Your Name"
                            className={`w-full pl-10 pr-4 py-2 border rounded-lg bg-white/50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${
                              formErrors.name ? "border-red-500" : "border-gray-300"
                            }`}
                            value={formState.name}
                            onChange={handleInputChange}
                          />
                          {formErrors.name && (
                            <p className="mt-1 text-sm text-red-500">
                              {formErrors.name}
                            </p>
                          )}
                        </div>

                        <div className="relative">
                          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <Building className="h-5 w-5 text-gray-400" />
                          </div>
                          <input
                            type="text"
                            id="company"
                            placeholder="Company Name"
                            className={`w-full pl-10 pr-4 py-2 border rounded-lg bg-white/50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${
                              formErrors.company ? "border-red-500" : "border-gray-300"
                            }`}
                            value={formState.company}
                            onChange={handleInputChange}
                          />
                          {formErrors.company && (
                            <p className="mt-1 text-sm text-red-500">
                              {formErrors.company}
                            </p>
                          )}
                        </div>

                        {/* Email is already entered, no need to show again */}
                        <div className="relative">
                          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <Mail className="h-5 w-5 text-gray-400" />
                          </div>
                          <input
                            type="email"
                            id="email"
                            placeholder="Email"
                            className={`w-full pl-10 pr-4 py-2 border rounded-lg bg-white/50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${
                              formErrors.email ? "border-red-500" : "border-gray-300"
                            }`}
                            value={formState.email}
                            readOnly
                          />
                          {formErrors.email && (
                            <p className="mt-1 text-sm text-red-500">
                              {formErrors.email}
                            </p>
                          )}
                        </div>

                        <div className="space-y-2">
                          <div className="relative">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                              <Lock className="h-5 w-5 text-gray-400" />
                            </div>
                            <input
                              type={showPassword ? "text" : "password"}
                              id="password"
                              placeholder="Password"
                              className={`w-full pl-10 pr-12 py-2 border rounded-lg bg-white/50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${
                                formErrors.password
                                  ? "border-red-500"
                                  : "border-gray-300"
                              }`}
                              value={formState.password}
                              onChange={handleInputChange}
                              onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                  handleNewUserJoin(e);
                                }
                              }}
                            />
                            <button
                              type="button"
                              onClick={() => setShowPassword(!showPassword)}
                              className="absolute inset-y-0 right-0 pr-3 flex items-center"
                            >
                              {showPassword ? (
                                <EyeOff className="h-5 w-5 text-gray-400" />
                              ) : (
                                <Eye className="h-5 w-5 text-gray-400" />
                              )}
                            </button>
                          </div>

                          <div className="text-sm space-y-1 px-2">
                            <p className={getColor(passwordChecks.length)}>
                              ✓ Minimum 8 characters
                            </p>
                            <p className={getColor(passwordChecks.uppercase)}>
                              ✓ One uppercase letter
                            </p>
                            <p className={getColor(passwordChecks.lowercase)}>
                              ✓ One lowercase letter
                            </p>
                            <p className={getColor(passwordChecks.number)}>
                              ✓ One number
                            </p>
                            <p className={getColor(passwordChecks.special)}>
                              ✓ One special character
                            </p>
                          </div>
                        </div>

                        <button
                          onClick={handleNewUserJoin}
                          className="w-full bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded-lg text-lg font-medium transition-colors duration-200"
                        >
                          Create Account & Join Team
                        </button>
                      </div>
                    )}
                  </>
                )}

                <button
                  onClick={() => history.push("/auth")}
                  className="w-full border border-blue-600 text-blue-600 hover:bg-blue-50 py-2 px-4 rounded-lg text-lg font-medium transition-colors duration-200"
                >
                  Cancel
                </button>
              </div>

              <div className="hidden md:block w-1/2">
                <img
                  src={image}
                  alt="leadfeed"
                  className="w-full h-full object-cover"
                />
              </div>
            </div>
          </div>
        </div>

        {isLoading && <LoadingSpinner asOverlay />}
      </div>
    </div>
  );
};

export default JoinTeam;
